import { Button, Tooltip } from "@blueprintjs/core";
import { clearSelection } from "Functions/Slot/clearSelection";
import { updateSlot } from "Functions/Slot/updateSlot";
import React from "react";

export default function ClearSelection({ slot }) {

  return (
    <Tooltip content="Select None">
      <Button icon="cross-circle" onClick={() => clearSelection(slot)} />
    </Tooltip>
  );
}
