import { Slider, Tooltip } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function RefinerSwitchAt() {
  return (
    <FlexBox fullWidth>
        <Slider
          min={0}
          max={1}
          stepSize={0.1}
          onChange={(value) => {
            apiSettings.value = {
              ...apiSettings.value,
              refiner_switch_at: value,
            };
          }}
          value={apiSettings.value.refiner_switch_at}
        >
          Refiner switch
        </Slider>
    </FlexBox>
  );
}
