import FlexBox from 'UtilityComponents/FlexBox'
import HeadingCard from 'UtilityComponents/HeadingCard'
import React from 'react'
import DeleteSlot from './DeleteSlot'

export default function Actions({slot}) {
  return (
    <HeadingCard title="Slot Actions">
        <FlexBox gap>
            <DeleteSlot slot={slot} />
        </FlexBox>
    </HeadingCard>
  )
}
