import { currentTokenId, tokens } from "Signals/tokens";
import generateGuid from "UtilityFunctions/generateGUID";

export const addToken = async (cardId, value, power, lora) => {
  // Work out order
  const lastToken = tokens.value
    .filter((t) => t.cardId === cardId)
    .sort((a, b) => b.order - a.order)[0];
  const order = lastToken ? lastToken.order + 1 : 0;
  // const order = 1

  const id = generateGuid();
  // Add token
  tokens.value = [
    ...tokens.value,
    {
      id: id,
      cardId: cardId,
      value: value,
      enabled: true,
      power: power ? parseFloat(power) : 1,
      order: order,
      lora: lora ? lora : false,
      negative: false,
    },
  ];

  currentTokenId.value = id;

  return id;
};
