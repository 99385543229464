import { snapshots } from "Signals/snapshots";

export const getFilteredSnapshots = (filter) => {
  return snapshots.value.filter((snapshot) => {
    return (
      snapshot.save &&
      (filter.tagIds.length === 0 ||
        (filter.inclusiveTags &&
          filter.tagIds.every((tagId) => snapshot.tagIds.includes(tagId))) ||
        (!filter.inclusiveTags &&
          filter.tagIds.some((tagId) => snapshot.tagIds.includes(tagId))))
    );
  });
};
