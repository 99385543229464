import { Card, Tooltip } from "@blueprintjs/core";
import { addTile } from "Functions/Mosaic/addTile";
import { categories, currentCategoryId } from "Signals/categories";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function CategoryName({ slot }) {
  // Get category name from slot categoryId
  const category = categories.value.find(
    (category) => category.id === slot.categoryId
  );

  const handleClick = () => {
    currentCategoryId.value = category.id;
    addTile("SingleCategory");
  };

  return (
    <Tooltip content="Open category settings">
      <Card interactive onClick={handleClick}>
        <PTEXT>{category.name}</PTEXT>
      </Card>
    </Tooltip>
  );
}
