export const defaultLayouts = {
  build: {
    direction: "column",
    first: "ListCategories",
    second: {
      first: "ListCards",
      second: "SingleCard",
      direction: "row",
      splitPercentage: 77.69296013570822,
    },
    splitPercentage: 34.940711462450594,
  },
  generate: {
    direction: "row",
    first: {
      first: "GenerateImage",
      second: "GenerationSettings",
      direction: "column",
      splitPercentage: 17.707509881422926,
    },
    second: {
      first: "SingleSnapshot",
      second: "ListSnapshots",
      direction: "row",
      splitPercentage: 37.62425447316103,
    },
    splitPercentage: 14.67345207803223,
  },
  viewList: "ListSnapshots",
  viewSaved: {
    first: "SingleSnapshot",
    second: "SavedSnapshots",
    direction: "row",
    splitPercentage: 30,
  },
  controlNet: {
    direction: "row",
    first: {
      first: "GenerateImage",
      second: "GenerationSettings",
      direction: "column",
      splitPercentage: 17.707509881422926,
    },
    second: {
      first: "ControlNet",
      second: "SingleSnapshot",
      direction: "row",
      splitPercentage: 37.62425447316103,
    },
    splitPercentage: 14.67345207803223,
  },
};
