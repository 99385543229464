import { Card, HTMLSelect, Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function Checkpoint() {
  const handleChange = (value) => {
    apiSettings.value = {
      ...apiSettings.value,
      checkpoint: value,
    };
  };
  return (
    <HeadingCard title="Primary Checkpoint">
      <HTMLSelect
        options={apiSettings.value.checkpoints}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        value={apiSettings.value.checkpoint}
      >
        {/* {settings.checkpoints?.map((checkpoint) => {
          return (
            <option key={checkpoint} value={checkpoint}>
              {checkpoint}
            </option>
          );
        })} */}
      </HTMLSelect>
    </HeadingCard>
  );
}
