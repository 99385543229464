import FlexBox from 'UtilityComponents/FlexBox'
import React from 'react'
import SaveLayout from './SaveLayout'
import ListLayouts from './ListLayouts'

export default function Layouts() {
  return (
    <FlexBox gap column padding>
        <SaveLayout />
        <ListLayouts />
    </FlexBox>
  )
}
