import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import ListCardsItem from "./ListCardsItem";

export default function ListCards({ slot }) {
  return (
    <FlexBox gap wrap>
      {slot.cardIds.map((cardId) => (
        <ListCardsItem key={cardId} slotId={slot.id} cardId={cardId} />
      ))}
    </FlexBox>
  );
}
