import { Checkbox, Switch, Tooltip } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import { currentSlot } from "Signals/slots";
import React from "react";

export default function ToggleInclusiveTags() {
  const slot = currentSlot.value;
  if(!slot.filter) return null;
  return (
    <Tooltip content="Card must contain every tag to match filter">
      <Checkbox
        className="m-0"
        checked={slot.filter.inclusiveTags}
        onChange={() =>
          updateSlot({
            ...slot,
            filter: {
              ...slot.filter,
              inclusiveTags: !slot.filter.inclusiveTags,
            },
          })
        }
      >
        Inclusive tag filter
      </Checkbox>
    </Tooltip>
  );
}
