import { Card } from "@blueprintjs/core";
import EmptyTile from "UtilityComponents/EmptyTile";
import React from "react";
import Actions from "./Actions/Actions";
import FlexBox from "UtilityComponents/FlexBox";
import { loadHand } from "Functions/Snapshot/loadHand";
import { currentSnapshotId, snapshots } from "Signals/snapshots";
import { settings } from "Signals/settings";

export default function ListSnapshots() {
  const handleClick = (snapshotId) => {
    currentSnapshotId.value = snapshotId;
  };

  const handleDoubleClick = (snapshot) => {
    loadHand(snapshot.hand);
  };

  const getStyle = () => {
    return {
      display: "grid",
      gridTemplateColumns: `repeat(auto-fill, minmax(${
        settings.value.listSnapshotsGridWidth || 300
      }px, 1fr))`,
      // gridTemplateColumns: `repeat(auto-fill, ${listSnapshotsGridWidth || 300}px)`
    };
  };

  const imageStyle = {
    width: "100%",
    // maxHeight: "100%",
    cursor: "pointer",
  };

  return (
    <FlexBox column padding gap>
      <Actions />
      {!snapshots.value || snapshots.value.length === 0 ? (
        <EmptyTile>No Snapshots</EmptyTile>
      ) : null}
      {snapshots && snapshots.value.length > 0 && (
        <div style={getStyle()}>
          {snapshots.value
            .slice()
            .sort((a, b) => {
              return b.created - a.created;
            })
            .map((snapshot, index) => (
              <div key={index}>
                {!snapshot.image && (
                  <Card
                    key={index}
                    interactive
                    onClick={() => handleClick(snapshot.id)}
                    onDoubleClick={() => handleDoubleClick(snapshot)}
                  >
                    <p>{snapshot.id}</p>
                    <p>No image</p>
                  </Card>
                )}
                {snapshot.image && (
                  <img
                    onClick={() => handleClick(snapshot.id)}
                    onDoubleClick={() => handleDoubleClick(snapshot)}
                    key={index}
                    style={imageStyle}
                    // src={`data:image/jpg;base64,${snapshot.image?.images[0]}`}
                    // TODO: Legacy - won't need it with newly saved images
                    src={snapshot.image.images ? `data:image/jpg;base64,${snapshot.image?.images[0]}` : snapshot.image}
                  />
                )}
              </div>
            ))}
        </div>
      )}
    </FlexBox>
  );
}
