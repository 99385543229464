import {computed, effect, signal} from "@preact/signals-react";

export const prompt = signal({
    positive: "",
    negative: ""
})
export const apiStatus = signal("disconnected")
export const apiConnected = signal(false)
export const apiProgress = signal(0)
export const cnImage = signal(null)
export const maskImage = signal(null)