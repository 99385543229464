import { Button, Tooltip } from '@blueprintjs/core'
import { updateSlot } from 'Functions/Slot/updateSlot'
import { currentSlot } from 'Signals/slots'
import React from 'react'

export default function ClearTags() {
  const slot = currentSlot.value

    const handleClick = () => {
      updateSlot({
        ...slot,
        filter: { ...slot.filter, 
        tagIds: [],
        },
      })
    }

  return (
    <Tooltip content="Clear Tags">
    <Button icon="cross-circle" onClick={handleClick} />
    </Tooltip>
  )
}
