import { Checkbox, Switch, SwitchCard, Tooltip } from "@blueprintjs/core";
import { updateCurrentCardLoraStatus } from "Functions/Card/updateCurrentCardLoraStatus";
import { updateToken } from "Functions/Token/updateToken";
import React from "react";

export default function ToggleTokenLora({ token }) {
  const handleToggle = () => {
    updateToken({ ...token, lora: !token.lora });
    updateCurrentCardLoraStatus();
  };

  return (
    <Checkbox
      disabled={!token.enabled}
      label="LORA"
      checked={token.lora}
      onChange={handleToggle}
    />
  );
}
