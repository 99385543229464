import { MenuDivider, MenuItem } from "@blueprintjs/core";
import { addTile } from "Functions/Mosaic/addTile";
import { isInLayout } from "Functions/Mosaic/isInLayout";
import React from "react";

export default function Generate() {
  return (
    <>
      <MenuItem text="Generate Images">
        <MenuItem
          text="Generation Buttons"
          onClick={() => addTile("GenerateImage")}
          disabled={isInLayout("GenerateImage")}
        />
        <MenuItem
          text="ControlNet"
          onClick={() => addTile("ControlNet")}
          disabled={isInLayout("ControlNet")}
        />
        <MenuItem
          text="Generation Settings"
          onClick={() => addTile("GenerationSettings")}
          disabled={isInLayout("GenerationSettings")}
        />
        <MenuItem
          text="API Settings"
          onClick={() => addTile("APISettings")}
          disabled={isInLayout("APISettings")}
        />
      </MenuItem>
    </>
  );
}
