import { cards } from "Signals/cards";
import { categories as stateCategories } from "Signals/categories";

export const getCardsFromHandInRenderOrder = (hand) => {
  if (!hand) return [];
  let slots = [...hand.slots];
  const categories = stateCategories.value
    .slice()
    .sort((a, b) => a.renderOrder - b.renderOrder);
  // Sort slots by category render order
  slots = slots.sort((a, b) => {
    const categoryA = categories.find(
      (category) => category.id === a.categoryId
    );
    const categoryB = categories.find(
      (category) => category.id === b.categoryId
    );
    return categoryA.renderOrder - categoryB.renderOrder;
  });

  const cardIds = slots.flatMap((slot) => slot.cardIds);
  let handCards = [];
  cardIds.forEach((cardId) => {
    const card = cards.value.find((card) => card.id === cardId);
    if (card) handCards.push(card);
  });
  return handCards;
};
