import { Card } from "@blueprintjs/core";
import React from "react";

export default function FlexCard(props) {
  const style = {
    // display: props.fullWidthColumn ? "block" : "flex",
    display: "flex",
    flexWrap: props.wrap? "wrap" : "nowrap",
    flexDirection: props.column ? "column" : "row",
    gap: props.gap ? "0.5rem" : 0,
    marginTop: props.mt ? "0.5rem" : 0,
    padding: props.padding ? "0.5rem" : 0,
    margin: props.margin ? "0.5rem" : 0,
    justifyContent: props.center
      ? "center"
      : props.spaceBetween
      ? "space-between"
      : props.spaceAround
      ? "space-around"
      : props.spaceEvenly
      ? "space-evenly"
      : props.flexStart
      ? "flex-start"
      : props.flexEnd
      ? "flex-end"
      : "center",
    alignItems: props.alignStart ? "flex-start" : "center",
  };
  return (
    <Card
      interactive = {props.interactive}
      // @ts-ignore
      style={style}
      className={`${props.className ? props.className : ""} ${
        props.column ? "fullWidthChildren" : ""
      }`}
    >
      {props.children}
    </Card>
  );
}
