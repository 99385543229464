import { TableCell, TableRow } from "@mui/material";
import React from "react";
import DeleteTag from "./DeleteTag";
import UpdateTagName from "./UpdateTagName";

export default function ListTagsItem({ tag }) {
  return (
    <tr>
      <td>
        <UpdateTagName tag={tag} />
      </td>
      {/* <td>{tag.color}</td> */}
      <td>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DeleteTag tagId={tag.id} />
        </div>
      </td>
    </tr>
  );
}
