import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import Hand from "./Hand/Hand";
import { Card, Tooltip } from "@blueprintjs/core";
import RenderImage from "UtilityComponents/RenderImage";
import SaveSnapshot from "./Actions/SaveSnapshot";
import DeleteSnapshot from "./Actions/DeleteSnapshot";
import EmptyTile from "UtilityComponents/EmptyTile";
import Info from "./Info/Info";
import ListSnapshotTags from "./Tags/ListSnapshotTags";
import Prompt from "./Prompt/Prompt";
import { currentSnapshot } from "Signals/snapshots";

export default function SingleSnapshot() {
  const snapshot = currentSnapshot.value;
  const imageStyle = {
    // width: "100%",
    height: "500px",
    cursor: "pointer",
  };

  const [fullImage, setFullImage] = React.useState(false);

  const toggleFullImage = () => {
    setFullImage(!fullImage);
  };

  if (!snapshot) return <EmptyTile>No Snapshot selected</EmptyTile>;

  if (fullImage)
    return (
      <div
        style={{ width: "100%", height: "100%", cursor: "pointer" }}
        onClick={toggleFullImage}
      >
        <RenderImage image={snapshot.image} />
      </div>
    );

  return (
    <FlexBox column padding gap>
      <Tooltip content="Enlarge">
        <FlexBox center>
          {snapshot.image && (
            <div style={imageStyle} onClick={toggleFullImage}>
              <RenderImage image={snapshot.image} />
            </div>
          )}
        </FlexBox>
      </Tooltip>
      <Card compact>
        <FlexBox gap>
          <SaveSnapshot snapshot={snapshot} />
          <DeleteSnapshot snapshot={snapshot} />
        </FlexBox>
      </Card>
      <ListSnapshotTags snapshot={snapshot} />
      <Hand hand={snapshot.hand} />
      <Prompt prompt={snapshot.prompt} />
      <Info snapshot={snapshot} />
    </FlexBox>
  );
}
