import { Button, Card, Popover } from "@blueprintjs/core";
import { saveLayout } from "Functions/Layout/saveLayout";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function SaveLayout() {
  const [name, setName] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);

  const handleAdd = () => {
    saveLayout(name);
    setName("");
    setIsOpen(false);
  };
  return (
    <FlexBox padding>
      <Popover
        isOpen={isOpen}
        onInteraction={(nextOpenState) => setIsOpen(nextOpenState)}
        autoFocus
        content={
          <div className="p-1">
            <p>Layout name:</p>
            <input
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAdd();
                }
              }}
            />
          </div>
        }
      >
        <Button fill icon="add" intent="primary">
          Save Current View
        </Button>
      </Popover>
    </FlexBox>
  );
}
