import { Card, NumericInput } from "@blueprintjs/core";
import { updateToken } from "Functions/Token/updateToken";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function UpdateTokenPower({ token }) {
  // const [power, setPower] = React.useState(token.power);

  const handleChange = (value) => {
    updateToken({ ...token, power: value });
  };

  return (
    <FlexBox gap flexStart>
      <NumericInput
        small
        style={{ width: "50px" }}
        disabled={!token.enabled}
        value={token.power}
        onValueChange={handleChange}
        // onBlur={handleChange}
        selectAllOnFocus
        min={-10}
        max={10}
        stepSize={0.1}
      />
      <span>Power</span>
    </FlexBox>
  );
}
