import { Spinner } from '@blueprintjs/core'
import React from 'react'

export default function Loading() {
  return (
    <div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Spinner/>
    </div>
  )
}
