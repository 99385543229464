import GetImage from "API/getImage";
import { addSnapshot } from "Functions/Snapshot/addSnapshot";
import getImageSeedFromSnapshotId from "Functions/Snapshot/getImageSeedFromSnapshotId";
import { updateSnapshot } from "Functions/Snapshot/updateSnapshot";
import { apiSettings } from "Signals/apiSettings";
import { cnImage } from "Signals/appState";
import { currentSnapshotId, snapshots } from "Signals/snapshots";

export const generateImage = async (options) => {
  // Create a snapshot first
  const snapshotId = addSnapshot();
  const snapshot = snapshots.value.find(
    (snapshot) => snapshot.id === snapshotId
  );

  // Create a copy of the API settings and add the seed and prompt to it
  const settings = {
    ...apiSettings.value,
    seed: options.reuseSeed
      ? await getImageSeedFromSnapshotId(currentSnapshotId.value)
      : "",
    prompt: snapshot.prompt,
  };

  // Update the settings copy according to options passed by generation buttons
  if (options.refinerEnabled) {
    settings.refiner_enabled = true;
    settings.ad_use_checkpoint = true;
  }
  if (options.useSecondaryCheckpoint) {
    settings.checkpoint = settings.secondaryCheckpoint;
    settings.refiner_enabled = false;
  }

  // Get image from API
  GetImage({
    settings: settings,
  }).then((image) => {
    if (!image) return;
    const snapshot = snapshots.value.find(
      (snapshot) => snapshot.id === snapshotId
    );
    console.log("images length: ", image.images.length)
    if (snapshot) {
      updateSnapshot({
        ...snapshot,
        image: `data:image/jpg;base64,${image.images[0]}`,
        info: image.info,
        cn: (cnImage.value && settings.cnEnabled) ? "true" : "false",
      })
      // snapshot.image = image;
      currentSnapshotId.value = snapshot.id;
    }
  });
};
