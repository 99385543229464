import { filteredCards } from "Signals/cards";
import { updateSlot } from "./updateSlot";
import { categories } from "Signals/categories";
import { updateCategory } from "Functions/Category/updateCategory";

export const selectAllCards = (slot) => {
  const cards = filteredCards.value;
  updateSlot({
    ...slot,
    cardIds: [...cards.map((card) => card.id)],
    // wildcard: false,
    enabled: true,
    multiSelect: true,
  });
  // Also enable category
  const category = categories.value.find(
    (category) => category.id === slot.categoryId
  );
  if (category && !category.enabled) {
    updateCategory({
      ...category,
      enabled: true,
    });
  }
};
