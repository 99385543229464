import { SwitchCard } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import React from "react";

export default function ToggleImg2Img() {
  return (
    <SwitchCard
      checked={apiSettings.value.img2imgEnabled}
      onChange={() => {
        apiSettings.value = {
          ...apiSettings.value,
          img2imgEnabled: !apiSettings.value.img2imgEnabled,
        };
      }}
    >
      Img2Img
    </SwitchCard>
  );
}
