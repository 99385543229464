import { Button, Card, Checkbox, InputGroup, Tooltip } from "@blueprintjs/core";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";
import { apiSettings } from "Signals/apiSettings";
import { apiConnected, apiStatus } from "Signals/appState";

export default function APISettings() {
  return (
    <FlexBox column padding gap>
      <Card>
        <PTEXT>
          API Status:{" "}
          {apiConnected.value ? "Connected" : "Disconnected"}
        </PTEXT>
        <PTEXT>API Message: {apiStatus.value}</PTEXT>
      </Card>
      <Card>
        <p>API URL</p>
        <InputGroup
          value={apiSettings.value.apiUrl}
          onChange={(e) => {
            apiSettings.value = {
              ...apiSettings.value,
              apiUrl: e.target.value,
            };
          }}
          placeholder="API URL"
        />
      </Card>
      <Card>
        <Tooltip content="Update A1111 API status every second. Disable if you will not be using A1111 for image generation.">
          <Checkbox
            checked={apiSettings.value.pollApi}
            onChange={(e) => {
              apiSettings.value = {
                ...apiSettings.value,
                pollApi: e.target.checked,
              };
            }}
          >
            Poll API
          </Checkbox>
        </Tooltip>
      </Card>
    </FlexBox>
  );
}
