import { Checkbox, Switch, SwitchCard } from "@blueprintjs/core";
import { updateToken } from "Functions/Token/updateToken";
import React from "react";

export default function ToggleTokenNegative({ token }) {
  return (
    <Checkbox
      disabled={!token.enabled}
      checked={token.negative}
      onChange={() => updateToken({ ...token, negative: !token.negative })}
    >
      Negative
    </Checkbox>
  );
}
