import { currentCard } from "Signals/cards";
import { cardContainsLora } from "./cardContainsLora";
import { updateCard } from "./updateCard";
import { cardClasses } from "@mui/material";

export const updateCurrentCardLoraStatus = async () => {
  const hasLora = cardContainsLora(currentCard.value.id);
  updateCard({
    ...currentCard.value,
    lora: hasLora,
  });
};
