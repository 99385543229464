import { Checkbox, SwitchCard } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import React from "react";

export default function ToggleEnabled() {
  return (
    <SwitchCard
      checked={apiSettings.value.cnEnabled}
      onChange={() => {
        apiSettings.value = {
          ...apiSettings.value,
          cnEnabled: !apiSettings.value.cnEnabled
        };
      }}
    >ControlNet</SwitchCard>
  );
}
