import { Button, Card } from "@blueprintjs/core";
import { deleteToken } from "Functions/Token/deleteToken";
import React from "react";

export default function DeleteToken({ tokenId }) {

  return (
      <Button
      small
        icon="trash"
        intent="danger"
        onClick={() => deleteToken(tokenId)}
        color="error"
      />
  );
}
