import { Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function ClipSkip() {

  const [value, setValue] = React.useState(apiSettings.value.clip_skip);
  return (
    <FlexBox column>
      <p>Clip Skip</p>
      <Slider
        min={0}
        max={10}
        labelStepSize={11}
        stepSize={1}
        onRelease={(value) => {
          apiSettings.value = {
            ...apiSettings.value,
            clip_skip: value,
          };
        }}
        onChange={(value) => {
          setValue(value);
        }}
        value={value}
      ></Slider>
    </FlexBox>
  );
}
