import { Button, Tooltip } from "@blueprintjs/core";
import { deleteSnapshot } from "Functions/Snapshot/deleteSnapshot";
import React from "react";

export default function DeleteSnapshot({ snapshot }) {
  const handleClick = (e) => {
    e.stopPropagation();
    deleteSnapshot(snapshot.id);
  };
  return (
    <Tooltip content="Delete Snapshot">
      <Button icon="trash" intent="danger" onClick={handleClick} />
    </Tooltip>
  );
}
