import { computed, effect, signal } from "@preact/signals-react";
import { saveSlotsToDb } from "Data/Slots/saveSlotsToDb";
import generateGuid from "UtilityFunctions/generateGUID";

// State
export const slots = signal([]);
export const currentSlotId = signal(null);
export const currentSlot = computed(() => {
  const slot = slots.value.filter((s) => s.id === currentSlotId.value)[0];
  return slot;
});

// Save to DB on change
effect(() => {
  if (!slots.value || slots.value.length === 0) return;
  saveSlotsToDb(slots.value);
});

// Broadcast changes to other tabs
export const channel = new BroadcastChannel("slots");
let isUpdatingFromBroadcast = false;
let broadcastID = generateGuid();

effect(() => {
  if (!slots.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "slots",
    data: slots.value,
    id: broadcastID,
  });
});

// Listen for changes from other tabs
channel.onmessage = (event) => {
  if (event.data.type === "slots" && event.data.id !== broadcastID) {
    isUpdatingFromBroadcast = true;
    slots.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
};
