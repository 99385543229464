import HeadingCard from 'UtilityComponents/HeadingCard'
import React from 'react'
import UpdatePrompt from './UpdatePrompt'

export default function Actions() {
  return (
    <HeadingCard title="Prompt Actions">
        <UpdatePrompt />
    </HeadingCard>
  )
}
