import { cards } from "Signals/cards";
import { categories } from "Signals/categories";
import { layouts } from "Signals/layouts";
import { slots } from "Signals/slots";
import { snapshots } from "Signals/snapshots";
import { tags } from "Signals/tags";
import { tokens } from "Signals/tokens";

export const exportData = async () => {
  const data = {
    cards: cards.value,
    tokens: tokens.value,
    tags: tags.value,
    slots: slots.value,
    categories: categories.value,
    snapshots: snapshots.value,
    layouts: layouts.value
  };
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const date = new Date();
  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}-${String(
    date.getHours()
  ).padStart(2, "0")}-${String(date.getMinutes()).padStart(2, "0")}`;
  const filename = `deck-${formattedDate}.json`;
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
