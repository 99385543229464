import { Button } from "@blueprintjs/core";
import { Tooltip } from "@mui/material";
import { updateSlot } from "Functions/Slot/updateSlot";
import React from "react";

export default function ToggleLock({ slot }) {
  const handleClick = () => {
    updateSlot({
      ...slot,
      lock: !slot.lock,
    });
  };
  return (
    <Tooltip title="Lock">
      <Button
        icon={slot.lock ? "lock" : "unlock"}
        intent={slot.lock ? "danger" : "success"}
        minimal
        onClick={handleClick}
      />
    </Tooltip>
  );
}
