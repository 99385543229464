import { Button } from "@blueprintjs/core";
import { cnImage } from "Signals/appState";
import { currentSnapshot } from "Signals/snapshots";
import React from "react";

export default function UseSnapshotImage() {
  const snapshot = currentSnapshot.value;
  const handleClick = () => {
    if (!currentSnapshot.value) return;
    cnImage.value = snapshot.image
  };
  return <Button onClick={handleClick}>Use Current Snapshot</Button>;
}
