import { computed, effect, signal } from "@preact/signals-react";
import { saveSnapshotsToDb } from "Data/Snapshots/saveSnapshotsToDb";
import generateGuid from "UtilityFunctions/generateGUID";

// State
export const snapshots = signal([]);
export const currentSnapshotId = signal(null);
export const currentSnapshot = computed(() => {
  const snapshot = snapshots.value.filter(
    (s) => s.id === currentSnapshotId.value
  )[0];
  return snapshot;
});

// Save to DB on change
effect(() => {
  if (!snapshots.value || snapshots.value.length === 0) return;
  saveSnapshotsToDb(snapshots.value);
});

// Broadcast changes to other tabs
export const channel = new BroadcastChannel("snapshots");
let isUpdatingFromBroadcast = false;
let broadcastID = generateGuid();

effect(() => {
  if (!snapshots.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "snapshots",
    data: snapshots.value,
    id: broadcastID,
  });
});

effect(() => {
  if (!currentSnapshotId.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "currentSnapshotId",
    data: currentSnapshotId.value,
    id: broadcastID,
  });
});

// Listen for changes from other tabs
channel.onmessage = (event) => {
  if (event.data.type === "snapshots" && event.data.id !== broadcastID) {
    isUpdatingFromBroadcast = true;
    snapshots.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
  if (
    event.data.type === "currentSnapshotId" &&
    event.data.id !== broadcastID
  ) {
    isUpdatingFromBroadcast = true;
    currentSnapshotId.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
};
