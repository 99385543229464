import { HTMLSelect } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function Upscaler() {
  return (
    <FlexBox column>
      <p>Upscaler</p>
      <HTMLSelect
        onChange={(event) => {
          apiSettings.value = {
            ...apiSettings.value,
            upscaler: event.target.value,
          };
        }}
        value={apiSettings.value.upscaler}
      >
        {apiSettings.value.upscalers.map((upscaler) => {
          return (
            <option key={upscaler} value={upscaler}>
              {upscaler}
            </option>
          );
        })}
      </HTMLSelect>
    </FlexBox>
  );
}
