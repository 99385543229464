import { SwitchCard } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import React from "react";

export default function ToggleEnabled({ slot }) {
  return (
    <SwitchCard
      checked={slot.enabled}
      // onChange={() => updateSlot(slot.id, { enabled: !slot.enabled })}
      onChange={() => updateSlot({ ...slot, enabled: !slot.enabled })}
    >
      Enabled
    </SwitchCard>
  );
}
