import { Card, EditableText, H2, H3 } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import BigText from "UtilityComponents/BigText";
import TitleText from "UtilityComponents/TitleText";
import React from "react";

export default function UpdateName({ slot }) {
  return (
    <Card>
      <TitleText>
        <EditableText
          value={slot.name}
          placeholder="Name"
          onChange={(value) => {
            updateSlot({ ...slot, name: value });
          }}
          selectAllOnFocus={true}
        />
      </TitleText>
    </Card>
  );
}
