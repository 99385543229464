import { Button, Tooltip } from "@blueprintjs/core";
import { deleteCard } from "Functions/Card/deleteCard";
import React from "react";

export default function DeleteCard({ card }) {
  return (
    <Tooltip content="Delete Card">
      <Button
        intent="danger"
        icon="trash"
        onClick={() => {
          deleteCard(card.id);
        }}
      />
    </Tooltip>
  );
}
