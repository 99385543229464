import { ContextMenuTargetLegacy } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import { cnImage, maskImage } from "Signals/appState";
import axios from "axios";
const GetImage = async (options) => {
  const settings = options.settings;
  const url =
    options.settings.apiUrl +
    (!settings.img2imgEnabled ? "/sdapi/v1/txt2img" : "/sdapi/v1/img2img");
  // TODO: This function only loads once so changes don't register until refresh. Something to do with async lol
  const data = {
    prompt: settings.prompt.positive,
    init_images: [cnImage.value],
    negative_prompt: settings.prompt.negative,
    width: settings.width || 512,
    height: settings.height || 768,
    sampler_name: settings.sampler || "DPM++ 2M Karras",
    steps: settings.steps || 20,
    refiner_checkpoint: settings.secondaryCheckpoint,
    refiner_switch_at: settings.refiner_enabled
      ? settings.refiner_switch_at
      : 1,
    restore_faces: settings.restore_faces,
    enable_hr: settings.enable_hr,
    hr_scale: settings.hr_scale,
    hr_upscaler: settings.upscaler,
    hr_second_pass_steps: settings.HRSteps,
    hr_checkpoint_name: settings.refiner_enabled
      ? settings.secondaryCheckpoint
      : settings.checkpoint,
    hr_prompt: settings.prompt.positive,
    hr_negative_prompt: settings.prompt.negative,
    denoising_strength: settings.img2imgEnabled
      ? settings.i2iDenoisingStrength
      : settings.denoising_strength,
    override_settings: {
      sd_model_checkpoint: settings.checkpoint,
      CLIP_stop_at_last_layers: settings.clip_skip,
    },
    cfg_scale: settings.cfg_scale,
    // hr_second_pass_steps: 15,

    mask: settings.useMask ? maskImage.value : null,

    resize_mode: settings.cnResizeMode,
    initial_noise_multiplier: settings.initial_noise_multiplier,
    inpaint_full_res: settings.inpaint_full_res,
    inpaint_full_res_padding: settings.inpaint_full_res_padding,
    inpainting_fill: settings.inpainting_fill,
    inpainting_mask_invert: settings.inpainting_mask_invert,
    mask_blur: settings.mask_blur,
    mask_blur_x: settings.mask_blur_x,
    mask_blur_y: settings.mask_blur_y,
    // include_init_images: false,

    alwayson_scripts: {
      controlnet: {
        args: [
          {
            mask: (settings.useCnMask && settings.useMask) ? maskImage.value : null,
            enabled: settings.cnEnabled,
            image: cnImage.value,
            module: settings.cnModule,
            model: settings.cnModel,
            weight: settings.cnWeight,
            resize_mode: settings.cnResizeMode,
            processor_res: settings.cnProcessorRes,
            guidance_start: settings.cnGuidanceStart,
            guidance_end: settings.cnGuidanceEnd,
            control_mode: settings.cnControlMode,
            pixel_perfect: settings.cnPixelPerfect,
            threshold_a: settings.cnThresholdA,
            threshold_b: settings.cnThresholdB,
          },
        ],
      },
      // "Regional Prompter": {
      //   args: [
      //     settings.enable_regional_prompter,
      //     false,
      //     "Prompt",
      //     "Vertical",
      //     "Mask",
      //     "Prompt-EX",
      //     "1,1,1",
      //     "",
      //     false,
      //     true,
      //     false,
      //     "Attention",
      //     false,
      //     "0",
      //     "0",
      //     "0.5,0.6,0.5",
      //     "",
      //   ],
      // },
      ADetailer: {
        args: [
          settings.adetailer_enable,
          false,
          {
            ad_model: "face_yolov8n.pt",
            ad_restore_face: settings.ad_restore_face,
            ad_checkpoint: settings.refiner_enabled
              ? settings.secondaryCheckpoint
              : settings.checkpoint,
            ad_use_checkpoint: true,
            ad_confidence: 0.8,
          },
        ],
      },
    },
  };

  if (settings.seed !== "") {
    data.seed = settings.seed;
  }

  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export default GetImage;
