import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import Actions from "./Actions/Actions";
import DisplayPrompt from "./DisplayPrompt";

export default function Prompt() {
  return <FlexBox padding column gap>
    <Actions />
    <DisplayPrompt />
  </FlexBox>;
}
