import { currentSlotId, slots } from "Signals/slots";
import generateGuid from "UtilityFunctions/generateGUID";
import { last } from "lodash";

export const addSlot = (name, categoryId) => {
  // Figure out last display order
  const lastSlot = slots.value
    .filter((slot) => slot.categoryId === categoryId)
    .sort((a, b) => b.displayOrder - a.displayOrder)[0];

  const id = generateGuid();
  slots.value = [
    ...slots.value,
    {
      id: id,
      name: name || "New Slot",
      categoryId: categoryId,
      enabled: true,
      color: "#000000",
      displayOrder: lastSlot ? lastSlot.displayOrder + 1 : 0,
      renderOrder: lastSlot ? lastSlot.renderOrder + 1 : 0,
      cardIds: [],
      filter: {
        searchTerm: "",
        tagIds: [],
        sort: "name",
        sortAsc: true,
        inclusiveTags: true,
      },
      multiSelect: false,
      wildcard: false,
      lock: false,
    },
  ];

  currentSlotId.value = id;
};
