import { slots } from "Signals/slots";
import { categories } from "Signals/categories";
export const buildHand = () => {
  const sortedCategories = categories.value
    .filter((category) => category.enabled)
    .sort((a, b) => a.displayOrder - b.displayOrder);

  const enabledSlots = sortedCategories.flatMap((category) =>
    slots.value
      .filter(
        (slot) =>
          slot.enabled &&
          slot.categoryId === category.id &&
          slot.cardIds.length > 0
      )
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map((slot) => ({
        id: slot.id,
        name: slot.name,
        cardIds: slot.wildcard
          ? [slot.cardIds[Math.floor(Math.random() * slot.cardIds.length)]]
          : [...slot.cardIds],
        categoryId: slot.categoryId,
        multiSelect: slot.multiSelect,
        wildcard: slot.wildcard,
      }))
  );

  let hand = {
    slots: enabledSlots,
  };
  return hand;
};
