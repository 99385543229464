import { addToken } from "Functions/Token/addToken";
import generateGuid from "UtilityFunctions/generateGUID";
import { setCurrentCardId } from "./setCurrentCardId";
import { currentSlot } from "Signals/slots";
import { updateSlot } from "Functions/Slot/updateSlot";
import { cards } from "Signals/cards";

export const addCard = (name) => {
  const id = generateGuid();

  const card = {
    id: id,
    name: name || "New Card",
    created: Date.now(),
    tagIds: [],
    color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    lora: false,
  };

  // If the current slot has any tags in filter.tagIds, add those tags to the new card
  if (currentSlot.value.filter.tagIds.length > 0) {
    card.tagIds = [...currentSlot.value.filter.tagIds];
  }

  // If we have a name, add a token to the new card with the same value as the card's name,
  if (name) {
    addToken(id, name);
  }

  // Add card to state
  cards.value = [
    ...cards.value,
    {
      ...card,
    },
  ];

  // Set as current card
  setCurrentCardId(id);

  // Add card to current slot
  updateSlot({
    ...currentSlot.value,
    cardIds: [id],
    enabled: true,
  });

  return id;
};
