import { Button, Card, Icon, InputGroup, Popover } from "@blueprintjs/core";
import { addCard } from "Functions/Card/addCard";
import { settings } from "Signals/settings";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function AddCard() {
  const [name, setName] = React.useState("");

  const handleAdd = () => {
    addCard(name);
    setName("");
  };

  const style = {
    marginTop: '1px', // Weird hack to make it look like the other cards
    height: settings.value.cardHeight,
    width: settings.value.cardWidth,
    cursor: "pointer",
    background: "#2D72D2", // TODO: Shouldn't be copying actual colour like this
    color: "white",
  };

  return (
    <Popover
      autoFocus
      content={
        <div className="p-1">
          <p>Card name:</p>
          <InputGroup
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAdd();
              }
            }}
          />
        </div>
      }
    >
      <Card interactive style={style}>
        <FlexBox fullHeight gap>
          <Icon icon="plus"/>
          <PTEXT>Add Card</PTEXT>
        </FlexBox>
      </Card>
    </Popover>
  );
}
