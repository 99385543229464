import { Card } from "@blueprintjs/core";
import React from "react";
import ToggleAdetailer from "./ToggleAdetailer";
import ToggleAdetailerRestoreFaces from "./ToggleAdetailerRestoreFaces";

export default function Adetailer() {
  return (
      <ToggleAdetailer />
  );
}
