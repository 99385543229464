import { NumericInput, Slider, Tooltip } from "@blueprintjs/core";
import { settings } from "Signals/settings";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function GridWidth() {
  return (
    <Tooltip content="Target image width in pixels. Images will stretch to fit remaining column space.">
      <FlexBox gap>
        <Slider
          min={10}
          max={2000}
          stepSize={10}
          labelStepSize={2000}
          value={settings.value.listSnapshotsGridWidth}
          onChange={(listSnapshotsGridWidth) =>
            (settings.value = { ...settings.value, listSnapshotsGridWidth })
          }
        />
        <NumericInput
          style={{ width: "60px" }}
          min={10}
          max={2000}
          stepSize={10}
          value={settings.value.listSnapshotsGridWidth}
          onValueChange={(listSnapshotsGridWidth) =>
            (settings.value = { ...settings.value, listSnapshotsGridWidth })
          }
        />
      </FlexBox>
    </Tooltip>
  );
}
