import { MenuItem } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { addTag } from "Functions/Tag/addTag";
import { tags } from "Signals/tags";
import { updateCard } from "Functions/Card/updateCard";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function ListCardTags({ card }) {
  const cardTags = tags.value.filter((tag) => card.tagIds.includes(tag.id));
  if (!tags || !cardTags) return <p>Loading...</p>;

  const handleDelete = (tagId) => {
    updateCard({
      ...card,
      tagIds: card.tagIds.filter((id) => id !== tagId),
    });
  };

  const handleAdd = (tagId) => {
    if (tags.value.some((t) => t.id === tagId)) {
      updateCard({
        ...card,
        tagIds: [...card.tagIds, tagId],
      });
    }
  };

  const handleNewTag = (name) => {
    const id = addTag(name);
    handleAdd(id);
  };

  return (
    <HeadingCard title="Tags">
      <MultiSelect
        items={tags.value.filter(
          (tag) => !cardTags.some((cardTag) => cardTag.id === tag.id)
        ).sort((a, b) => a.name.localeCompare(b.name))}
        tagRenderer={(tag) => tag.name}
        tagInputProps={{
          onRemove: (value, index) => {
            const tag = cardTags[index];
            handleDelete(tag.id);
          },
          placeholder: "Add tag...",
        }}
        onItemSelect={(tag) => {
          handleAdd(tag.id);
        }}
        selectedItems={cardTags}
        itemPredicate={(query, item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        }}
        itemRenderer={(item, { handleClick, modifiers }) => {
          return (
            <MenuItem
              key={item.id}
              onClick={() => handleAdd(item.id)}
              text={item.name}
              active={modifiers.active}
            />
          );
        }}
        createNewItemFromQuery={(name) => {
          return { id: uuidv4(), name };
        }}
        createNewItemRenderer={(query, active, handleClick) => {
          return (
            <MenuItem
              icon="add"
              text={`Add new tag: ${query}`}
              active={active}
              onClick={() => handleNewTag(query)}
              onSelect={() => handleNewTag(query)}
              shouldDismissPopover={false}
            />
          );
        }}
        popoverContentProps={{ className: "scrollable-dropdown" }}
      />
    </HeadingCard>
  );
}
