import { NumericInput, Tooltip } from "@blueprintjs/core";
import { settings } from "Signals/settings";
import React from "react";

export default function GridWidth() {
  return (
    <Tooltip content="Target image width in pixels. Images will stretch to fit remaining column space.">
      <NumericInput
        style={{ width: "60px" }}
        min={10}
        max={2000}
        stepSize={10}
        value={settings.value.listSavedSnapshotsGridWidth}
        onValueChange={(listSavedSnapshotsGridWidth) =>
          (settings.value = {
            ...settings.value,
            listSavedSnapshotsGridWidth,
          })
        }
      />
    </Tooltip>
  );
}
