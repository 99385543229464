import { Card, Checkbox, Icon } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import { currentSlotId } from "Signals/slots";
import DeleteSlot from "Tiles/SingleSlot/Actions/DeleteSlot";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function ListSlotsItem({ slot }) {

  const handleClick = () => {
    currentSlotId.value = slot.id;
  };

  return (
    <Card compact className={!slot.enabled && "disabled"} onClick={handleClick}>
      <FlexBox spaceBetween gap>
        <FlexBox gap>
          <Icon icon="drag-handle-vertical" />
          <Checkbox
            checked={slot.enabled}
            onChange={() => {
              updateSlot({
                ...slot,
                enabled: !slot.enabled,
              });
            }}
          />
          <PTEXT>{slot.name}</PTEXT>
        </FlexBox>
        <DeleteSlot slot={slot} />
      </FlexBox>
    </Card>
  );
}
