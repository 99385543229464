import { Button, Card, TextArea, Tooltip } from "@blueprintjs/core";
import { buildCardPrompt } from "Functions/Card/buildCardPrompt";
import { tokens } from "Signals/tokens";
import FlexBox from "UtilityComponents/FlexBox";
import FlexCard from "UtilityComponents/FlexCard";
import HeadingCard from "UtilityComponents/HeadingCard";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function Prompt({ card }) {
  const prompt = buildCardPrompt(
    tokens.value
      .filter((t) => t.cardId === card.id)
      .sort((a, b) => a.order - b.order)
  );

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <HeadingCard title="Generated Prompt">
      <FlexBox column gap>
        <FlexBox spaceBetween gap>
          <PTEXT>Prompt</PTEXT>
          <Tooltip content="Copy to clipboard">
            <Button
              icon="clipboard"
              onClick={() => copyToClipboard(prompt.positive)}
            />
          </Tooltip>
        </FlexBox>
        <TextArea value={prompt.positive} fill readOnly />
        <FlexBox spaceBetween gap>
          <PTEXT>Negative Prompt</PTEXT>
          <Tooltip content="Copy to clipboard">
            <Button
              icon="clipboard"
              onClick={() => copyToClipboard(prompt.negative)}
            />
          </Tooltip>
        </FlexBox>
        <TextArea value={prompt.negative} fill readOnly />
      </FlexBox>
    </HeadingCard>
  );
}
