import generateGuid from "UtilityFunctions/generateGUID";
import { buildHand } from "../Prompt/buildHand";
import { buildPromptFromHand } from "../Prompt/buildPromptFromHand";
import { snapshots } from "Signals/snapshots";

export const addSnapshot = () => {
  const id = generateGuid();
  const hand = buildHand();
  const prompt = buildPromptFromHand(hand);
  snapshots.value = [
    ...snapshots.value,
    {
      id,
      name: "New Snapshot",
      created: Date.now(),
      hand: hand,
      prompt: prompt,
      image: undefined,
      save: false,
      tagIds: [],
    },
  ];
  return id;
};
