import { computed, effect, signal } from "@preact/signals-react";
import { defaultSettings } from "Data/Settings/defaultSettings";
import { saveSettingsToDb } from "Data/Settings/saveSettingsToDb";
import generateGuid from "UtilityFunctions/generateGUID";
import _ from "lodash";

// State
export const settings = signal(defaultSettings);

// Save to DB on change
effect(() => {
  if (!settings.value) return;
  // Don't save if default settings
  if(_.isEqual(settings.value, defaultSettings)) return;
  saveSettingsToDb(settings.value);
});

// Broadcast changes to other tabs
export const channel = new BroadcastChannel("settings");
let isUpdatingFromBroadcast = false;
let broadcastID = generateGuid();

effect(() => {
  if (!settings.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "settings",
    data: settings.value,
    id: broadcastID,
  });
});

// Listen for changes from other tabs
channel.onmessage = (event) => {
  if (event.data.type === "settings" && event.data.id !== broadcastID) {
    isUpdatingFromBroadcast = true;
    settings.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
};
