import { computed, effect, signal } from "@preact/signals-react";
import { saveCategoriesToDb } from "Data/Categories/saveCategoriesToDb";
import generateGuid from "UtilityFunctions/generateGUID";

// State
export const categories = signal([]);
export const currentCategoryId = signal(null);
export const currentCategory = computed(() => {
  const category = categories.value.filter(
    (c) => c.id === currentCategoryId.value
  )[0];
  return category;
});

// Save to DB on change
effect(() => {
  if (!categories.value || categories.value.length === 0) return;
  saveCategoriesToDb(categories.value);
});

// Broadcast changes to other tabs
export const channel = new BroadcastChannel("categories");
let isUpdatingFromBroadcast = false;
let broadcastID = generateGuid();

effect(() => {
  if (!categories.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "categories",
    data: categories.value,
    id: broadcastID,
  });
});

channel.onmessage = (event) => {
  if (event.data.type === "categories" && event.data.id !== broadcastID) {
    isUpdatingFromBroadcast = true;
    categories.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
};
