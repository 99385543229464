import { Card, MenuItem } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { updateSnapshot } from "Functions/Snapshot/updateSnapshot";
import { addTag } from "Functions/Tag/addTag";
import { tags } from "Signals/tags";
import HeadingCard from "UtilityComponents/HeadingCard";
import { set } from "lodash";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export default function ListSnapshotTags({ snapshot }) {
  const snapshotTags = tags.value.filter((tag) =>
    snapshot.tagIds.includes(tag.id)
  );

  const handleAdd = (tagId) => {
    if (tags.value.some((t) => t.id === tagId)) {
      updateSnapshot({
        ...snapshot,
        tagIds: [...snapshot.tagIds, tagId],
      });
    }
  };

  const handleDelete = (tagId) => {
    updateSnapshot({
      ...snapshot,
      tagIds: snapshot.tagIds.filter((id) => id !== tagId),
    });
  };

  const handleNewTag = (name) => {
    const id = addTag(name);
    handleAdd(id);
  };

  return (
    <HeadingCard title="Tags">
      <MultiSelect
        items={tags.value}
        tagRenderer={(tag) => tag.name}
        tagInputProps={{
          onRemove: (value, index) => {
            const tag = snapshotTags[index];
            handleDelete(tag.id);
          },
          placeholder: "Add tag...",
        }}
        onItemSelect={(tag) => {
          handleAdd(tag.id);
        }}
        selectedItems={snapshotTags}
        itemPredicate={(query, item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        }}
        itemRenderer={(item, { handleClick, modifiers }) => {
          return (
            <MenuItem
              key={item.id}
              onClick={() => handleAdd(item.id)}
              text={item.name}
              active={modifiers.active}
            />
          );
        }}
        createNewItemFromQuery={(name) => {
          return { id: uuidv4(), name };
        }}
        createNewItemRenderer={(query, active, handleClick) => {
          return (
            <MenuItem
              icon="add"
              text={`Add new tag: ${query}`}
              active={active}
              onClick={() => handleNewTag(query)}
              onSelect={() => handleNewTag(query)}
              shouldDismissPopover={false}
            />
          );
        }}
        popoverContentProps={{ className: "scrollable-dropdown" }}
      />
    </HeadingCard>
  );
}
