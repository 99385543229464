import { getSlotsFromDb } from "Data/Slots/getSlotsFromDb";
import { slots } from "../../Signals/slots";
import { getCategoriesFromDb } from "Data/Categories/getCategoriesFromDb";
import { categories } from "../../Signals/categories";
import { getTokensFromDb } from "Data/Tokens/getTokensFromDb";
import { tokens } from "../../Signals/tokens";
import { getCardsFromDb } from "Data/Cards/getCardsFromDb";
import { cards } from "../../Signals/cards";
import { getTagsFromDb } from "Data/Tags/getTagsFromDb";
import { tags } from "../../Signals/tags";
import { getSnapshotsFromDb } from "Data/Snapshots/getSnapshotsFromDb";
import { snapshots } from "../../Signals/snapshots";
import { getSettingsFromDb } from "Data/Settings/getSettingsFromDb";
import { settings } from "../../Signals/settings";
import { getApiSettingsFromDb } from "Data/ApiSettings/getApiSettingsFromDb";
import { apiSettings } from "../../Signals/apiSettings";
import { getLayoutsFromDb } from "Data/Layouts/getLayoutsFromDb";
import { layouts } from "../../Signals/layouts";
import { getDefaultApiSettings } from "Data/ApiSettings/defaultApiSettings";
import { defaultSettings } from "Data/Settings/defaultSettings";

export const getInitialData = async () => {
  getCategoriesFromDb().then((data) => {
    if (!data) {
      categories.value = [];
      return;
    }
    categories.value = data;
  });

  getSlotsFromDb().then((data) => {
    if (!data) {
      slots.value = [];
      return;
    }
    slots.value = data;
  });

  getCardsFromDb().then((data) => {
    if (!data) {
      cards.value = [];
      return;
    }
    cards.value = data;
  });

  getTokensFromDb().then((data) => {
    if (!data) {
      tokens.value = [];
      return;
    }
    tokens.value = data;
  });

  getTagsFromDb().then((data) => {
    if (!data) {
      tags.value = [];
      return;
    }
    tags.value = data;
  });

  getSnapshotsFromDb().then((data) => {
    if (!data) {
      snapshots.value = [];
      return;
    }
    snapshots.value = data;
  });

  getLayoutsFromDb().then((data) => {
    if (!data) {
      layouts.value = [];
      return;
    }
    layouts.value = data;
  });

  getSettingsFromDb().then((data) => {
    if (!data) {
      settings.value = defaultSettings;
      return;
    }
    settings.value = data;
  });

  getApiSettingsFromDb().then((data) => {
    if (!data) {
      apiSettings.value = getDefaultApiSettings();
      return;
    }
    apiSettings.value = data;
  });
};
