import { Button, InputGroup, Tooltip } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import { currentSlot } from "Signals/slots";
import FlexBox from "UtilityComponents/FlexBox";
import React, { useEffect } from "react";

export default function SearchTerm() {
  const slot = currentSlot.value;
  if(!slot.filter) return null;
  return (
    <FlexBox>
      <InputGroup
        placeholder="Search"
        value={slot.filter ? slot.filter.searchTerm : ""}
        onChange={(e) =>
          updateSlot({
            ...slot,
            filter: { ...slot.filter, searchTerm: e.target.value },
          })
        }
      />
      <Tooltip content="Clear Search Term">
        <Button
          icon="cross-circle"
          onClick={() =>
            updateSlot({ ...slot, filter: { ...slot.filter, searchTerm: "" } })
          }
        />
      </Tooltip>
    </FlexBox>
  );
}
