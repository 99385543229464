import { TextField } from "@mui/material";
import React from "react";
import { Button, Card, InputGroup } from "@blueprintjs/core";
import { addTag } from "Functions/Tag/addTag";
import HeadingCard from "UtilityComponents/HeadingCard";
import FlexBox from "UtilityComponents/FlexBox";

export default function AddTag() {
  const [name, setName] = React.useState("");

  const handleAdd = () => {
    if (!name) return;
    addTag(name);
    setName("");
  };

  return (
    <HeadingCard title="Add Tag">
      <FlexBox>
        <InputGroup
          style={{ width: "200px" }}
          placeholder="Add New Tag"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleAdd();
            }
          }}
          onBlur={handleAdd}
        />
        <Button onClick={handleAdd} intent="primary" icon="add">
          Add
        </Button>
      </FlexBox>
    </HeadingCard>
  );
}
