import { Checkbox } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import React from "react";

export default function ToggleCNMask() {
  const toggleMask = () => {
    apiSettings.value = {
      ...apiSettings.value,
      useCnMask: !apiSettings.value.useCnMask,
    };
  };
  return (
    <Checkbox
      checked={apiSettings.value.useCnMask}
      onChange={toggleMask}
      label="Use CN Mask"
    />
  );
}
