import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import ListCards from "./ListCards";

export default function ListSlotsItem({ slot }) {
  return (
    // <Card className="p-0">
    //   <FlexBox spaceBetween>
    //     <FlexBox column padding flexStart>
    //     </FlexBox>
        <ListCards slot={slot} />
    //   </FlexBox>
    // </Card>
  );
}
