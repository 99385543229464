import { Button } from '@blueprintjs/core'
import { addTile } from 'Functions/Mosaic/addTile'
import React from 'react'

export default function ReorderCategoriesButton() {
    const handleClick = () => {
        addTile("ReorderCategories")
    }
  return (
    <Button onClick={handleClick} icon="sort">Reorder Categories</Button>
  )
}
