import { MenuDivider, MenuItem } from "@blueprintjs/core";
import { addTile } from "Functions/Mosaic/addTile";
import { isInLayout } from "Functions/Mosaic/isInLayout";
import React from "react";

export default function Build() {

  return (
    <MenuItem text="PROMPT">
      <MenuItem
        text="Prompt Board"
        onClick={() => addTile("ListCategories")}
        disabled={isInLayout("ListCategories")}
      />
      <MenuItem
        text="Prompt Board (compact)"
        onClick={() => addTile("ListCategoriesCompact")}
        disabled={isInLayout("ListCategoriesCompact")}
      />
      <MenuItem
        text="Deck"
        onClick={() => addTile("ListCards")}
        disabled={isInLayout("ListCards")}
      />
      <MenuItem
        text="Deck Filter"
        onClick={() => addTile("ListCardsOptions")}
        disabled={isInLayout("ListCardsOptions")}
      />
      <MenuItem
        text="Card"
        onClick={() => addTile("SingleCard")}
        disabled={isInLayout("SingleCard")}
      />
      <MenuItem
        text="Slot"
        onClick={() => addTile("SingleSlot")}
        disabled={isInLayout("SingleSlot")}
      />
      <MenuItem
        text="Category"
        onClick={() => addTile("SingleCategory")}
        disabled={isInLayout("SingleCategory")}
      />
      <MenuItem
        text="Generated Prompt"
        onClick={() => addTile("Prompt")}
        disabled={isInLayout("Prompt")}
      />
      <MenuItem
        text="Tags"
        onClick={() => addTile("ListTags")}
        disabled={isInLayout("ListTags")}
      />
    </MenuItem>
  );
}
