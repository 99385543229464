import { Button } from '@blueprintjs/core'
import { maskImage } from 'Signals/appState'
import React from 'react'

export default function ClearMask() {
    const handleClear = () => {
        maskImage.value = null
    }
  return (
    <Button onClick={handleClear}>Clear Mask</Button>
  )
}
