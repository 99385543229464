import { Box, TableCell, TableRow, TextField, Typography } from "@mui/material";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import DeleteToken from "./DeleteToken";
import ToggleTokenEnable from "./ToggleTokenEnable";
import ToggleTokenNegative from "./ToggleTokenNegative";
import ToggleTokenLora from "./ToggleTokenLora";
import UpdateTokenValue from "./UpdateTokenValue";
import UpdateTokenPower from "./UpdateTokenPower";
import { Button, Card, Icon, NonIdealState } from "@blueprintjs/core";
import { currentToken } from "Signals/tokens";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function SingleToken() {
  const token = currentToken.value;

  // if (!token)
  //   return <NonIdealState title="No token selected" icon="warning-sign" />;
  return (
    <HeadingCard title="Edit Token">
      {!token && (
        <NonIdealState title="No token selected" icon="warning-sign" />
      )}
      {token && (
        <FlexBox column gap>
          <FlexBox gap>
            <UpdateTokenValue token={token} />
            <DeleteToken tokenId={token.id} />
          </FlexBox>
          <FlexBox flexStart gap column>
            <ToggleTokenEnable token={token} />
            <ToggleTokenLora token={token} />
            <ToggleTokenNegative token={token} />
            <UpdateTokenPower token={token} />
          </FlexBox>
        </FlexBox>
      )}
    </HeadingCard>
  );
}
