import React from "react";
import ListCardTags from "./Tags/ListCardTags";
import UpdateName from "./Actions/UpdateName";
import DeleteCard from "./Actions/DeleteCard";
import Prompt from "./Prompt/Prompt";
import EmptyTile from "UtilityComponents/EmptyTile";
import FlexCard from "UtilityComponents/FlexCard";
import FlexBox from "UtilityComponents/FlexBox";
import CardImage from "./Image/CardImage";
import CloneCard from "./Actions/CloneCard";
import { currentCard } from "Signals/cards";
import HeadingCard from "UtilityComponents/HeadingCard";
import AddToken from "./Tokens/AddToken";
import ListTokens from "./Tokens/ListTokens/ListTokens";
import SingleToken from "./Tokens/SingleToken/SingleToken";

export default function SingleCard() {
  const card = currentCard.value;

  if (!card) return <EmptyTile>No card selected</EmptyTile>;

  return (
    <FlexBox gap column padding>
      <CardImage card={card} />
      <UpdateName card={card} />
      <ListTokens cardId={card.id} />
      <SingleToken />
      <AddToken cardId={card.id} />
      <ListCardTags card={card} />
      <Prompt card={card} />
      <HeadingCard title="Card Actions">
        <FlexBox gap>
          <CloneCard card={card} />
          <DeleteCard card={card} />
        </FlexBox>
      </HeadingCard>
    </FlexBox>
  );
}
