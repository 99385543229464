export default function getFilteredCards(cards, filter) {
  if (!filter) {
    return cards;
  }

  let filteredCards = cards.slice();
  // Always filter by search term first
  filteredCards = cards.filter((card) =>
    card.name.toLowerCase().includes(filter.searchTerm.toLowerCase())
  );
  // Then filter by tag
  // Filter by tag list first
  filteredCards = filteredCards.filter(
    (card) =>
      filter.tagIds.length === 0 ||
      (filter.inclusiveTags &&
        filter.tagIds.every((tagId) => card.tagIds.includes(tagId))) ||
      (!filter.inclusiveTags &&
        filter.tagIds.some((tagId) => card.tagIds.includes(tagId)))
  );
  // If we have a specifically selected tag, filter by that second
  if (filter.selectedTagId) {
    filteredCards = filteredCards.filter((card) =>
      card.tagIds.includes(filter.selectedTagId)
    );
  }

  // Then sort
  filteredCards = filteredCards.sort((a, b) => {
    if (filter.sort === "name") {
      return (
        (filter.sortAsc ? 1 : -1) *
        a.name.localeCompare(b.name, undefined, {
          sensitivity: "base",
        })
      );
    } else if (filter.sort === "created") {
      const dateA = new Date(a.created);
      const dateB = new Date(b.created);
      return (filter.sortAsc ? -1 : 1) * (dateA.getTime() - dateB.getTime());
    } else {
      return 0;
    }
  });
  return filteredCards;
}
