import ELEMENTS from "Mosaic/Elements";
import { currentLayout } from "Signals/layouts";

export const addTile = (tileId, width) => {
  let layout = currentLayout.value
  let newLayout;
  if (layout) {
    // Check whether tile is already in layout - if so we don't want to open it again
    if (JSON.stringify(layout).includes(`"${tileId}"`)) return;

    // Get width from ELEMENTS layout
    const width = ELEMENTS.find((element) => element.id === tileId).width;

    newLayout = {
      direction: "row",
      first: tileId,
      second: layout,
      splitPercentage: width ? width : 20,
    };
  } else {
    newLayout = tileId;
  }
  currentLayout.value = newLayout;
};
