import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import ListCategoriesItem from "./ListCategoriesItem";
import { categories } from "Signals/categories";

export default function ListCategories({ hand }) {
  return (
    <>
      <p>Load by category:</p>
      <FlexBox gap wrap>
        {categories.value.map((category) => (
          <ListCategoriesItem
            key={category.id}
            category={category}
            hand={hand}
          />
        ))}
      </FlexBox>
    </>
  );
}
