import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import { Card, NonIdealState } from "@blueprintjs/core";
import ListLayoutsItem from "./ListLayoutsItem";
import { layouts } from "Signals/layouts";

export default function ListLayouts() {
  return (
    <FlexBox column gap>
      {layouts.value.length === 0 && (
        <NonIdealState
          title="No layouts found"
          description="Try saving the current layout to get started"
          icon="warning-sign"
        />
      )}

      {layouts.value.map((layout) => (
        <ListLayoutsItem key={layout.id} layout={layout} />
      ))}
    </FlexBox>
  );
}
