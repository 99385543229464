import { cards } from "Signals/cards";

export const updateCard = (card) => {
  cards.value = cards.value.map((c) => {
    if (c.id === card.id) {
      return card;
    }
    return c;
  });
};
