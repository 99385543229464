import { tags } from "Signals/tags";

export const updateTag = async (tag) => {
  tags.value = tags.value.map((t) => {
    if (t.id === tag.id) {
      return tag;
    }
    return t;
  });
};
