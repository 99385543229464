import { Button, Tooltip } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import { updateSnapshot } from "Functions/Snapshot/updateSnapshot";
import React from "react";

export default function SaveSnapshot({ snapshot }) {
  const handleClick = (e) => {
    e.stopPropagation();
    updateSnapshot({
      ...snapshot,
      save: !snapshot.save,
    });
  };
  return (
    <Tooltip content="Save Snapshot">
      <Button
        icon="floppy-disk"
        intent={snapshot.save ? "success" : "none"}
        onClick={handleClick}
      />
    </Tooltip>
  );
}
