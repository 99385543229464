import { Button, Tooltip } from "@blueprintjs/core";
import { updateCard } from "Functions/Card/updateCard";
import React from "react";

export default function ClearImage({ card }) {
  const clearImage = () => {
    updateCard({
      ...card,
      image: "",
    });
  };

  const style = {
    position: "absolute",
    top: "0px",
    right: "0px",
  };

  return (
    <div 
// @ts-ignore
    style={style}>
    <Tooltip content="Clear Image" position="bottom">
      <Button icon="delete" onClick={clearImage} />
    </Tooltip>
    </div>
  );
}
