import { Button, Card, Collapse } from "@blueprintjs/core";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import PTEXT from "UtilityComponents/PTEXT";
import { loadHandCategory } from "Functions/Snapshot/loadHandCategory";

export default function ListCategoriesItem({ category, hand }) {
  const slots = hand.slots.filter((slot) => slot.categoryId === category.id);
  if (!slots || slots.length === 0) return null;

  const handleCardClick = () => {
    loadHandCategory(category, hand);
  };
  return (
    <Card interactive onClick={handleCardClick}>
      <FlexBox gap column>
        <FlexBox>
          <PTEXT>{category.name}</PTEXT>
        </FlexBox>
      </FlexBox>
      {/* <Collapse isOpen={true}>
        <ListSlots slots={slots} />
      </Collapse> */}
    </Card>
  );
}
