export const buildCardPrompt = (tokens) => {
  // console.log(tokens)
  const prompt = {
    positive: "",
    negative: "",
  };
  if (!tokens) return prompt;

  tokens.map((token) => {
    if (!token.enabled) return;
    let value = token.value;
    if (token.power && token.power != 1) {
      value = `(${token.value}:${token.power})`;
    }
    if (token.lora) {
      value = getLora(token);
    }
    if (token.negative) {
      prompt.negative += value + ", ";
    } else {
      prompt.positive += value + ", ";
    }
  });

  // Strip final comma
  prompt.positive = prompt.positive.slice(0, -2);
  prompt.negative = prompt.negative.slice(0, -2);
  return prompt;
};

const getLora = (token) => {
  return `<lora:${token.value}:${token.power}>`;
};
