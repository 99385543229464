import { apiSettings } from "Signals/apiSettings";
import { apiStatus} from "Signals/appState";
import axios from "axios";

export const getUpscalers = async () => {
  const url = `${apiSettings.value.apiUrl}/sdapi/v1/upscalers`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    apiSettings.value = {
      ...apiSettings.value,
      upscalers: data.flatMap((upscaler) => upscaler.name),
    };
  } catch {
    apiStatus.value = "Error"
  }
};
