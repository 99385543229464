import { Checkbox } from "@blueprintjs/core";
import React from "react";

export default function ToggleInclusiveTags({ filter, setFilter }) {
  return (
    <Checkbox
      className="m-0"
      checked={filter.inclusiveTags}
      onChange={() =>
        setFilter({ ...filter, inclusiveTags: !filter.inclusiveTags })
      }
      label="Inclusive search"
    />
  );
}
