import { Button, Card, Overlay } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import { cnImage, maskImage } from "Signals/appState";
import FlexBox from "UtilityComponents/FlexBox";
import { set } from "lodash";
import Painterro from "painterro";
import React, { useLayoutEffect } from "react";
import { useEffect, useState } from "react";
import UploadImage from "./UploadImage";
import Paint from "./PaintMask";
import ShowImage from "./ShowImage";
import PaintMask from "./PaintMask";
import ToggleMask from "./ToggleMask";
import ClearMask from "./ClearMask";
import ToggleCNMask from "./ToggleCNMask";
import HeadingCard from "UtilityComponents/HeadingCard";
import Settings from "./Settings/Settings";

export default function MaskImage() {
  const image = maskImage.value;

  return (
    <FlexBox column gap>
      <ToggleMask />
      {apiSettings.value.useMask && (
        <HeadingCard title="Mask settings">
          <FlexBox gap column>
            <FlexBox gap spaceBetween>
              <FlexBox column>
                <ToggleCNMask />
              </FlexBox>
              {image && <ClearMask />}
              <PaintMask />
            </FlexBox>
            {/* {image && <ShowImage image={image} />} */}
          </FlexBox>
          <Settings />
        </HeadingCard>
      )}
    </FlexBox>
  );
}
