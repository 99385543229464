import { SwitchCard } from '@blueprintjs/core';
import { apiSettings } from 'Signals/apiSettings';
import React from 'react'

export default function ToggleHRFix() {
  return (
    <SwitchCard
      checked={apiSettings.value.enable_hr}
      onChange={() => {
        apiSettings.value = {
          ...apiSettings.value,
          enable_hr: !apiSettings.value.enable_hr,
        };
      }}
      >HiRes fix</SwitchCard>
  )
}
