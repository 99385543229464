import { H2 } from '@blueprintjs/core'
import React from 'react'

export default function TitleText(props) {

    const style = {
        textAlign: 'center',
        margin:0,
    }

  return (
    <H2 
// @ts-ignore
    style={style}>{props.children}</H2>
  )
}
