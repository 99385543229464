import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import SavedLayouts from "./SavedLayouts/SavedLayouts";
import AddTileMenu from "./AddTileMenu";
import DefaultLayoutsMenu from "./DefaultLayouts/DefaultLayoutsMenu";
import APIStatus from "./APIStatus/APIStatus";
import { Card, CardList, Classes, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import Build from "./Menus/Build";
import Generate from "./Menus/Generate";
import Settings from "./Menus/Settings";
import Snapshots from "./Menus/Snapshots";
import { List } from "@blueprintjs/icons";
import ListLayouts from "./SavedLayouts/ListLayouts";
import SaveLayout from "./SavedLayouts/SaveLayout";

export default function NavMenu() {
  return (
    <FlexBox gap column flexStart alignStart>
      <APIStatus />
      <Menu large className="uppercase m-0 p-1">
        <MenuDivider title="Views" />
        <DefaultLayoutsMenu />
        <MenuDivider title="Windows" />
        <Build />
        {/* <MenuDivider/> */}
        <Generate />
        {/* <MenuDivider/> */}
        <Snapshots />
        {/* <MenuDivider/> */}
        <Settings />
        <MenuDivider title="Saved Views" />
        <ListLayouts />
        <SaveLayout />
      </Menu>
    </FlexBox>
  );
}
