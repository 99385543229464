import React from "react";
import DeleteCategory from "./DeleteCategory";
import HeadingCard from "UtilityComponents/HeadingCard";
import FlexBox from "UtilityComponents/FlexBox";

export default function Actions({ category }) {
  return (
    <HeadingCard title="Category Actions">
      <FlexBox gap>
        <DeleteCategory category={category} />
      </FlexBox>
    </HeadingCard>
  );
}
