import { Card, Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function DenoisingStrength() {

  return (
    <FlexBox column>
    <p>Denoising Strength</p>
      <Slider
        min={0}
        max={1}
        stepSize={0.05}
        labelStepSize={1}
        onChange={(value) => {
          apiSettings.value = {
            ...apiSettings.value,
            i2iDenoisingStrength: value,
          };
        }}
        value={apiSettings.value.i2iDenoisingStrength}
      />
    </FlexBox>
  );
}
