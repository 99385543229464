import React from "react";
import { SwitchCard } from "@blueprintjs/core";
import { updateCategory } from "Functions/Category/updateCategory";

export default function ToggleEnabled({ category }) {
  return (
    <SwitchCard
      checked={category.enabled}
      onChange={() => (updateCategory({ ...category, enabled: !category.enabled }))}
    >
      Enabled
    </SwitchCard>
  );
}
