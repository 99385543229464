import React from "react";
import ListTagsItem from "./ListTagsItem";
import AddTag from "./AddTag";
import { Card, HTMLTable, Spinner } from "@blueprintjs/core";
import { tags } from "Signals/tags";
import HeadingCard from "UtilityComponents/HeadingCard";
import FlexBox from "UtilityComponents/FlexBox";

export default function ListTags() {
  return (
    <FlexBox padding gap column>
      <AddTag />
      <HeadingCard title="Tags">
        <HTMLTable bordered style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {tags.value.map((tag) => (
              <ListTagsItem key={tag.id} tag={tag} />
            ))}
          </tbody>
        </HTMLTable>
      </HeadingCard>
    </FlexBox>
  );
}
