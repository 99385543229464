import { MenuDivider, MenuItem } from "@blueprintjs/core";
import { addTile } from "Functions/Mosaic/addTile";
import { isInLayout } from "Functions/Mosaic/isInLayout";
import React from "react";

export default function Snapshots() {
  return (
    <MenuItem text="Snapshots">
      <MenuItem
        text="Current Snapshot"
        onClick={() => addTile("SingleSnapshot")}
        disabled={isInLayout("SingleSnapshot")}
      />
      <MenuItem
        text="List Snapshots"
        onClick={() => addTile("ListSnapshots")}
        disabled={isInLayout("ListSnapshots")}
      />
      <MenuItem
        text="Saved Snapshots"
        onClick={() => addTile("SavedSnapshots")}
        disabled={isInLayout("SavedSnapshots")}
      />
    </MenuItem>
  );
}
