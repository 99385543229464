import DisplayCard from "UtilityComponents/DisplayCard";
import { Text } from "@blueprintjs/core";
import RenderCardImage from "UtilityComponents/RenderCardImage";
import React from "react";
import { setCurrentCardId } from "Functions/Card/setCurrentCardId";

export default function ListCardsItem({ card, disabled, index }) {
  const handleClick = (e) => {
    setCurrentCardId(card.id);
  };

  const style = {
    // marginLeft: index === 0 ? 0 : `-${cardWidth / 3}px`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    // gap: "0.5rem",
  };

  const imageStyle = {
    width: "20px",
    height: "30px",
    border: "1px solid gray",
  };

  const titleStyle = {
    width: "70px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return (
      <DisplayCard card={card} onClick={handleClick} disabled={disabled} onDoubleClick={undefined} />
  );
}
