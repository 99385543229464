import { Card, Tooltip } from "@blueprintjs/core";
import React from "react";
import RefinerSwitchAt from "./RefinerSwitchAt";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function Refiner() {
  return (
    <HeadingCard title="Refiner Switch Point">
        <RefinerSwitchAt />
    </HeadingCard>
  );
}
