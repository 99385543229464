import { computed, effect, signal } from "@preact/signals-react";
import { saveCardsToDb } from "Data/Cards/saveCardsToDb";
import getFilteredCards from "Functions/Card/getFilteredCards";
import generateGuid from "UtilityFunctions/generateGUID";
import { currentSlot, slots } from "./slots";
import { updatePrompt } from "Functions/Prompt/updatePrompt";

// State
export const cards = signal([]);
export const currentCardId = signal(null);
export const currentCard = computed(() => {
  const card = cards.value.filter((s) => s.id === currentCardId.value)[0];
  return card;
});
export const filteredCards = computed(() => {
  return getFilteredCards(cards.value, currentSlot.value.filter);
});

// Save to DB on change
effect(() => {
  if (!cards.value || cards.value.length === 0) return;
  saveCardsToDb(cards.value);
});

// Broadcast changes to other tabs
export const channel = new BroadcastChannel("cards");
let isUpdatingFromBroadcast = false;
let broadcastID = generateGuid();

effect(() => {
  if (!cards.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "cards",
    data: cards.value,
    id: broadcastID,
  });
});

channel.onmessage = (event) => {
  if (event.data.type === "cards" && event.data.id !== broadcastID) {
    isUpdatingFromBroadcast = true;
    cards.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
};