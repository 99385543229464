import React from 'react'

export default function RenderImage({image}){
    const style = {
        maxWidth:'100%',
        maxHeight:'100%',
        // height: props.fullHeight ? '100%' : 'auto',
        height: '100%',
        objectFit:'contain',
    }
  return (
    // @ts-ignore
    // <img style={style} src={`data:image/jpg;base64,${props.image?.images[0]}`} />
    <img style={style} src={image}/>
  )
}
