import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";
import { prompt } from "Signals/appState";
import { Button, InputGroup, TextArea, Tooltip } from "@blueprintjs/core";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";

export default function DisplayPrompt() {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <HeadingCard title="Generated Prompt">
      <FlexBox column gap>
        <FlexBox spaceBetween gap>
          <PTEXT>Prompt</PTEXT>
          <Tooltip content="Copy to clipboard">
            <Button
              icon="clipboard"
              onClick={() => copyToClipboard(prompt.value.positive)}
            />
          </Tooltip>
        </FlexBox>
        <TextArea value={prompt.value.positive} readOnly autoResize />
        <FlexBox spaceBetween gap>
          <PTEXT>Negative prompt</PTEXT>
          <Tooltip content="Copy to clipboard">
            <Button
              icon="clipboard"
              onClick={() => copyToClipboard(prompt.value.negative)}
            />
          </Tooltip>
        </FlexBox>
        <TextArea value={prompt.value.negative} readOnly autoResize />
      </FlexBox>
    </HeadingCard>
  );
}
