import { Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React, { useState } from "react";

export default function MaskBlur() {
  const [value, setValue] = useState(apiSettings.value.mask_blur);
  return (
    <FlexBox column>
      <p>Mask Blur</p>
      <Slider
        min={0}
        max={64}
        stepSize={1}
        labelValues={[]}
        onRelease={(value) => {
          apiSettings.value = {
            ...apiSettings.value,
            mask_blur: value,
          };
        }}
        onChange={(value) => {
          setValue(value);
          apiSettings.value.mask_blur = value;
        }}
        value={value}
      />
    </FlexBox>
  );
}
