import { Card } from "@blueprintjs/core";
import React from "react";
import ToggleHRFix from "./ToggleHRFix";
import HRScale from "./HRScale";
import Upscaler from "./Upscaler";
import HRSteps from "./HRSteps";
import DenoisingStrength from "./DenoisingStrength";
import HeadingCard from "UtilityComponents/HeadingCard";
import FlexBox from "UtilityComponents/FlexBox";
import { apiSettings } from "Signals/apiSettings";

export default function HRFix() {
  return (
    <FlexBox column gap>
      <ToggleHRFix />
      {apiSettings.value.enable_hr && (
        <HeadingCard title="HiRes fix settings">
          <FlexBox column gap>
          <Upscaler />
          <HRScale />
          <HRSteps />
          <DenoisingStrength />
          </FlexBox>
        </HeadingCard>
      )}
    </FlexBox>
  );
}
