import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import AddSnapshot from "./AddSnapshot";
import DeleteSnapshots from "./DeleteSnapshots";
import { Card } from "@blueprintjs/core";
import GridWidth from "./GridWidth";

export default function Actions() {
  return (
    <Card>
      <FlexBox spaceBetween>
        <div>
          <AddSnapshot />
          <DeleteSnapshots />
        </div>
        <GridWidth />
      </FlexBox>
    </Card>
  );
}
