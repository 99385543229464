import { Card, Divider, Section, SectionCard } from "@blueprintjs/core";
import React from "react";

export default function HeadingCard(props) {
  const style = {
    margin: 0,
    textTransform: "uppercase",
    // off black color
    color: "#333333",
  };

  const dividerStyle = {
    // width: "50%",
    // marginTop:0,
    // marginBottom: "0.5rem",
  };

  const cardStyle = {
    padding: "0.5rem",
  };

  const contentStyle = {
    // paddingBottom: "0.5rem",
    padding: props.compact ? "0.5rem" : "1rem",

  };

  return (
    <Card style={cardStyle}>
      {props.title && (
        <div
          // @ts-ignore
          style={style}
        >
          {props.title}
        </div>
      )}
      {props.title && <Divider style={dividerStyle} />}
      <div style={contentStyle}>{props.children}</div>
    </Card>
    // <Section title={props.title} collapsible>
    //   <SectionCard>
    //     {props.children}
    //   </SectionCard>
    // </Section>
  );
}
