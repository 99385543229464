import React from "react";
import ClearSelection from "./ClearSelection";
import ToggleMultiSelect from "./ToggleMultiSelect";
import SelectAll from "./SelectAll";
import ToggleWildcard from "./ToggleWildcard";
import FlexBox from "UtilityComponents/FlexBox";
import { Card } from "@blueprintjs/core";
import ListSelectedCards from "../ListSelectedCards/ListSelectedCards";
import HeadingCard from "UtilityComponents/HeadingCard";
import SlotName from "./SlotName";
import CategoryName from "./CategoryName";

export default function SlotOptions({ slot }) {
  return (
    <HeadingCard compact title="Slot Options">
      <FlexBox wrap gap spaceBetween>
        <FlexBox wrap gap flexStart>
          <ToggleMultiSelect slot={slot} />
          <ToggleWildcard slot={slot} />
          <SelectAll slot={slot} />
          <ClearSelection slot={slot} />
        </FlexBox>
        <FlexBox gap>
          <SlotName slot={slot} />
          <CategoryName slot={slot} />
        </FlexBox>
      </FlexBox>
    </HeadingCard>
  );
}
