import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import SlotItem from "./SlotItem";
import { slots } from "Signals/slots";

export default function CategoryItem({ category }) {
  return (
    <FlexBox column>
      <p>{category.name}</p>
      {slots.value
        .filter((slot) => slot.categoryId === category.id)
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((slot) => (
          <SlotItem key={slot.id} slot={slot} />
        ))}
    </FlexBox>
  );
}
