import { apiSettings } from "Signals/apiSettings";
import axios from "axios";

const getCheckpoints = async () => {
  const url = `${apiSettings.value.apiUrl}/sdapi/v1/sd-models`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    apiSettings.value = {
      ...apiSettings.value,
      checkpoints: data.flatMap((checkpoint) => checkpoint.title),
    };
  } catch (error) {
    // state.apiStatus = "Error fetching data from API";
  }
};

export default getCheckpoints;
