import { tokens } from "Signals/tokens";
import generateGuid from "UtilityFunctions/generateGUID";

export const cloneToken = (token, cardId) => {
  const newToken = {
    value: token.value,
    power: token.power,
    lora: token.lora,
    negative: token.negative,
    id: generateGuid(),
    cardId: cardId,
    enabled: token.enabled,
    order: token.order,
  };
  tokens.value = [...tokens.value, newToken];
};
