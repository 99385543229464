import React from "react";
import ListCategoriesItem from "./ListCategoriesItem/ListCategoriesItem";
import { categories } from "Signals/categories";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import Actions from "Tiles/ListCategories/Actions/Actions";

export default function ListCategoriesCompact() {
  return (
    <FlexBox padding column gap>
        <FlexBox gap alignStart>
          {categories.value
            .slice()
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((category) => (
              <ListCategoriesItem key={category.id} category={category} />
            ))}
        </FlexBox>
      <Actions />
    </FlexBox>
  );
}
