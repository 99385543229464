import { Button, Popover } from "@blueprintjs/core";
import { addSlot } from "Functions/Slot/addSlot";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function AddSlot({ categoryId }) {
  // NOTE: Also used by ListCategories
  const [name, setName] = React.useState("");

  const handleAdd = () => {
    addSlot(name, categoryId);
    setName("");
  };
  return (
    <FlexBox>
      <Popover
        autoFocus
        content={
          <div className="p-1">
            <p>Slot name:</p>
            <input
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAdd();
                }
              }}
            />
          </div>
        }
      >
        <Button icon="add" intent="primary">
          Add Slot
        </Button>
      </Popover>
    </FlexBox>
  );
}
