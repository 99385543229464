import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";
import DenoisingStrength from "./DenoisingStrength";

export default function I2ISettings() {
  return (
    <HeadingCard title="img2img Settings">
      <DenoisingStrength />
    </HeadingCard>
  );
}
