import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { Button } from "@blueprintjs/core";
import { deleteTag } from "Functions/Tag/deleteTag";

export default function DeleteTag({ tagId }) {
  return (
    <Button
      small
      onClick={() => deleteTag(tagId)}
      intent="danger"
      icon="trash"
    />
  );
}
