import { Card, Tab, Tabs } from "@blueprintjs/core";
import React from "react";
import DisplayOrder from "./DisplayOrder";
import RenderOrder from "./RenderOrder";
import FlexBox from "UtilityComponents/FlexBox";
import FlexCard from "UtilityComponents/FlexCard";

export default function ReorderCategories() {
  return (
    <FlexCard padding margin>

    <Tabs
    className="fullWidth"
      id="TabsExample"
      defaultSelectedTabId="displayOrder"
    >
        <Tab id="displayOrder" title="Display Order" panel={<DisplayOrder />} />
        <Tab id="renderOrder" title="Process Order" panel={<RenderOrder/>} />
    </Tabs>
    </FlexCard>
  );
}
