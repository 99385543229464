import { snapshots } from "Signals/snapshots";

export const updateSnapshot = async (snapshot) => {
  snapshots.value = snapshots.value.map((s) => {
    if (s.id === snapshot.id) {
      return snapshot;
    }
    return s;
  });
};
