import { computed, effect, signal } from "@preact/signals-react";
import { getDefaultApiSettings } from "Data/ApiSettings/defaultApiSettings";
import { getApiSettingsFromDb } from "Data/ApiSettings/getApiSettingsFromDb";
import { saveApiSettingsToDb } from "Data/ApiSettings/saveApiSettingsToDb";
import generateGuid from "UtilityFunctions/generateGUID";
import _, { get } from "lodash";

export const getApiSettings = async () => {
  const data = await getApiSettingsFromDb();
  if (!data) return getDefaultApiSettings();
  return data;
};

// State
export const apiSettings = signal(getDefaultApiSettings());

// Save to DB on change
effect(() => {
  if (!apiSettings.value) return;
  // HACK
  // Compare value with default settings, don't save if they are identical
  // const defaultSettings = getDefaultApiSettings();
  if (_.isEqual(apiSettings.value, getDefaultApiSettings())) return;
  saveApiSettingsToDb(apiSettings.value);
});

// Broadcast changes to other tabs
export const channel = new BroadcastChannel("apiSettings");

let isUpdatingFromBroadcast = false;
let broadcastID = generateGuid();

effect(() => {
  if (!apiSettings.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "apiSettings",
    data: apiSettings.value,
    id: broadcastID,
  });
});

// Listen for changes from other tabs
channel.onmessage = (event) => {
  if (event.data.type === "apiSettings" && event.data.id !== broadcastID) {
    isUpdatingFromBroadcast = true;
    apiSettings.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
};
