import { cards } from "Signals/cards";
import { categories } from "Signals/categories";
import { layouts } from "Signals/layouts";
import { slots } from "Signals/slots";
import { snapshots } from "Signals/snapshots";
import { tags } from "Signals/tags";
import { tokens } from "Signals/tokens";
import { produce } from "immer";

export const importData = async (file, merge) => {
  const reader = new FileReader();

  reader.onload = async (event) => {
    const data = JSON.parse(event.target.result);
    console.log("Importing data...", data);
    if (!merge) {
      cards.value = [];
      tokens.value = [];
      tags.value = [];
      slots.value = [];
      categories.value = [];
      snapshots.value = [];
      layouts.value = [];
    }

    tags.value = produce(tags.value, (draft) => {
      draft.push(
        ...data.tags.filter(
          (tag) => !draft.some((stateTag) => stateTag.id === tag.id)
        )
      );
    });
    slots.value = produce(slots.value, (draft) => {
      draft.push(
        ...data.slots.filter(
          (slot) => !draft.some((stateSlot) => stateSlot.id === slot.id)
        )
      );
    });
    categories.value = produce(categories.value, (draft) => {
      draft.push(
        ...data.categories.filter(
          (category) =>
            !draft.some((stateCategory) => stateCategory.id === category.id)
        )
      );
    });
    tokens.value = produce(tokens.value, (draft) => {
      draft.push(
        ...data.tokens.filter(
          (token) => !draft.some((stateToken) => stateToken.id === token.id)
        )
      );
    });
    cards.value = produce(cards.value, (draft) => {
      draft.push(
        ...data.cards.filter(
          (card) => !draft.some((stateCard) => stateCard.id === card.id)
        )
      );
    });
    snapshots.value = produce(snapshots.value, (draft) => {
      draft.push(
        ...data.snapshots.filter(
          (snapshot) =>
            !draft.some((stateSnapshot) => stateSnapshot.id === snapshot.id)
        )
      );
    });
    layouts.value = produce(layouts.value, (draft) => {
      draft.push(
        ...data.layouts.filter(
          (layout) => !draft.some((stateLayout) => stateLayout.id === layout.id)
        )
      );
    });
  };

  reader.readAsText(file);
};
