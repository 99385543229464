import { Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function UpdateSteps() {
  const [steps, setSteps] = React.useState(apiSettings.value.steps);

  return (
    <FlexBox column>
      <p>Steps</p>
      <Slider
        min={1}
        max={50}
        labelValues={[]}
        stepSize={1}
        onRelease={(value) => {
          apiSettings.value = {
            ...apiSettings.value,
            steps: value,
          };
        }}
        onChange={(value) => {
          setSteps(value);
        }}
        value={steps}
      />
    </FlexBox>
  );
}
