import { cards } from "Signals/cards";
import { currentSlot } from "Signals/slots";
import { tags } from "Signals/tags";

export const getAvailableTags = () => {
  // Returns a list of tags that are available to select given the current slot's filter options and already selected tags
  const slot = currentSlot.value;
  if (!slot) return null;

  // Get all cards based on the slot filter and return all tags that are on those cards
  const filteredCards = cards.value.filter(
    (card) =>
      slot.filter.tagIds.length === 0 ||
      slot.filter.tagIds.every((tagId) => card.tagIds.includes(tagId))
  );
  const filteredTags = tags.value.filter((tag) =>
    filteredCards?.some((card) => card.tagIds.includes(tag.id))
  );

  // Filter out any tags that are already in slot.filter.tagIds
  return filteredTags.filter((tag) => !slot.filter.tagIds.includes(tag.id));
};
