import { SwitchCard } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import React from "react";

export default function ToggleRestoreFaces() {
  return (
    <SwitchCard
      checked={apiSettings.value.restore_faces}
      onChange={() => {
        apiSettings.value = {
          ...apiSettings.value,
          restore_faces: !apiSettings.value.restore_faces,
        };
      }}
    >
      Restore faces
    </SwitchCard>
  );
}
