import { Card, Tooltip } from "@blueprintjs/core";
import { addTile } from "Functions/Mosaic/addTile";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function SlotName({ slot }) {
  const handleClick = () => {
    addTile("SingleSlot");
  };
  return (
    <Tooltip content="Open slot settings">
      <Card interactive onClick={handleClick}>
        <PTEXT>{slot.name}</PTEXT>
      </Card>
    </Tooltip>
  );
}
