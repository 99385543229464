import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import FlexBox from "../../../UtilityComponents/FlexBox";
import { Card } from "@blueprintjs/core";
import PTEXT from "../../../UtilityComponents/PTEXT";
import { updateCard } from "Functions/Card/updateCard";
import RenderCardImage from "UtilityComponents/RenderCardImage";
import { cnImage, maskImage } from "Signals/appState";
import ShowImage from "./ShowImage";

export default function UploadImage() {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        cnImage.value = binaryStr;
        maskImage.value = null;
        // console.log(binaryStr)
      };
      reader.readAsDataURL(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Card>
        {cnImage.value && <ShowImage/>}
        {!cnImage.value && (
          <FlexBox fullHeight>
            <PTEXT>Drag an image or click to upload</PTEXT>
          </FlexBox>
        )}
      </Card>
    </div>
  );
}
