import { useSortable } from "@dnd-kit/sortable";
import React from "react";
import { CSS } from "@dnd-kit/utilities";
import { Tag } from "@blueprintjs/core";
import { setCurrentCardId } from "Functions/Card/setCurrentCardId";
import { deleteToken } from "Functions/Token/deleteToken";
import { currentTokenId } from "Signals/tokens";
import { updateToken } from "Functions/Token/updateToken";

export default function DraggableListTokensItem({ token }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: token.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    // transition,
  };

  const handleClick = () => {
    currentTokenId.value = token.id;
  };

  const handleDoubleClick = () => {
    updateToken({
      ...token,
      enabled: !token.enabled,
    });
  };

  const handleRemove = () => {
    deleteToken(token.id);
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Tag
        large
        onRemove={handleRemove}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        interactive
        active={token.id === currentTokenId.value}
        minimal={!token.enabled}
        intent={token.negative ? "danger" : token.lora ? "success" : "none"}
      >
        {token.value} {token.power !== 1 && <span>({token.power})</span>}
      </Tag>
    </div>
  );
}
