import { Button, Overlay } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import { cnImage, maskImage } from "Signals/appState";
import { set } from "lodash";
import Painterro from "painterro";
import React, { useLayoutEffect } from "react";
import { useEffect, useState } from "react";

export default function PaintMask() {
  const source = cnImage.value;
  const mask = maskImage.value;
  const [isOpen, setIsOpen] = useState(false);
  const [p, setP] = useState(null);

  const initializePainterro = () => {
    var p = Painterro({
      saveHandler: function (image, done) {
        maskImage.value = image.asDataURL();
        done(true);
        setIsOpen(false);
      },
      onClose: function () {
        setIsOpen(false);
      },
      // defaultSize: `${(apiSettings.value.width.toFixed(0))}x${apiSettings.value.height.toFixed(0)}}`,
      defaultSize: "512x768",
      id: "painterro2",
      backplateImgUrl: source,
      activeColor: '#ffffff',
      activeFillColor: '#ffffff',
      defaultTool: 'rect',
      backgroundFillColor: "#000000",
      backgroundFillColorAlpha: 1
    });
    p.show(null);
    setP(p);
  };

  const handleClose = () => {
    setIsOpen(false);
    p.hide();
  };

  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90%",
    width: "90%",
    marginLeft: "5%",
    marginTop: "5%",
  };

  return (
    <div>
      <Button onClick={() => setIsOpen(true)}>Paint Mask</Button>
      <Overlay
        isOpen={isOpen}
        onClose={handleClose}
        onOpened={initializePainterro}
      >
        <div id="painterro2" style={style}></div>
      </Overlay>
    </div>
  );
}
