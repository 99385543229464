import { Checkbox, Switch, Tooltip } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import React from "react";

export default function ToggleWildcard({ slot }) {
  return (
    <Tooltip content="Pick one of the selected cards when generating prompt">
      <Checkbox
        disabled={!slot.multiSelect}
        style={{ margin: 0 }}
        checked={slot.wildcard}
        onChange={() => updateSlot({ ...slot, wildcard: !slot.wildcard })}
      >
        Wildcard
      </Checkbox>
    </Tooltip>
  );
}
