import DisplayCard from "UtilityComponents/DisplayCard";
import { Card, Text } from "@blueprintjs/core";
import RenderCardImage from "UtilityComponents/RenderCardImage";
import React from "react";
import { setCurrentCardId } from "Functions/Card/setCurrentCardId";
import PTEXT from "UtilityComponents/PTEXT";
import FlexBox from "UtilityComponents/FlexBox";
import { getImageSrc } from "Functions/Snapshot/getImageSrc";

export default function ListCardsItem({ card, disabled, index }) {
  const handleClick = (e) => {
    setCurrentCardId(card.id);
  };

  const style = {
    padding: "0.5rem",
  };
  const imageStyle = {
    height: "20px",
    opacity: disabled ? 0.2 : 1,
  };

  return (
    <Card
      style={style}
      interactive
      onClick={handleClick}
      className={disabled && "disabled"}
    >
      <FlexBox>
          <PTEXT>{card.name}</PTEXT>
      </FlexBox>
    </Card>
  );
}
