import { Button } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import { slots } from "Signals/slots";
import React from "react";

export default function UnlockAll() {
  const unlockAllSlots = () => {
    slots.value.forEach((slot) => {
      updateSlot({
        ...slot,
        lock: false,
      });
    });
  };

  return (
    <Button icon="unlock" onClick={unlockAllSlots}>
      Unlock All
    </Button>
  );
}
