import { deleteSlot } from "Functions/Slot/deleteSlot";
import { categories } from "Signals/categories";
import { slots } from "Signals/slots";

export const deleteCategory = (categoryId) => {
  categories.value = categories.value.filter(
    (category) => category.id !== categoryId
  );
  const categorySlots = slots.value.filter(
    (slot) => slot.categoryId === categoryId
  );
  categorySlots.forEach((slot) => {
    deleteSlot(slot.id);
  });
};
