import { Button, Tooltip } from "@blueprintjs/core";
import { deleteCategory } from "Functions/Category/deleteCategory";
import React from "react";

export default function DeleteCategory({ category }) {
  return (
    <Tooltip content="Delete Category">
    <Button
      icon="trash"
      intent="danger"
      onClick={() => {
        deleteCategory(category.id);
      }}
    />
    </Tooltip>
  );
}
