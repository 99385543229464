import FlexCard from "UtilityComponents/FlexCard";
import React from "react";
import UpdateName from "./UpdateName";
import DeleteCategory from "./Actions/DeleteCategory";
import EmptyTile from "UtilityComponents/EmptyTile";
import ListSlots from "./ListSlots/ListSlots";
import AddSlot from "./AddSlot";
import ToggleEnabled from "./ToggleEnabled";
import FlexBox from "UtilityComponents/FlexBox";
import { Card } from "@blueprintjs/core";
import { currentCategory } from "Signals/categories";
import Actions from "./Actions/Actions";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function SingleCategory() {
  const category = currentCategory.value;
  if (!category) return <EmptyTile>No Category Selected</EmptyTile>;
  return (
    <FlexBox padding gap column>
      <UpdateName category={category} />
      <ToggleEnabled category={category} />
      <HeadingCard title="Add Slot">
        <AddSlot categoryId={category.id} />
      </HeadingCard>
      <ListSlots categoryId={category.id} />
      <Actions category={category} />
    </FlexBox>
  );
}
