import { cards, currentCardId } from "Signals/cards";
import { tokens } from "Signals/tokens";

export const deleteCard = (cardId) => {
  cards.value = cards.value.filter((c) => c.id !== cardId);
  currentCardId.value = null;

  // Also delete all tokens associated with this card
  tokens.value = tokens.value.filter((t) => t.cardId !== cardId);
};
