import { Button, Card, H5 } from "@blueprintjs/core";
import { exportData } from "Functions/Data/exportData";
import { cards } from "Signals/cards";
import { categories } from "Signals/categories";
import { layouts } from "Signals/layouts";
import { slots } from "Signals/slots";
import { snapshots } from "Signals/snapshots";
import { tags } from "Signals/tags";
import { tokens } from "Signals/tokens";
import FlexBox from "UtilityComponents/FlexBox";
import { getSizeInMB } from "UtilityFunctions/getSizeInMB";
import React from "react";

export default function ExportData() {
  return (
    <Card>
      <FlexBox column>
        <H5>Export Data</H5>
        <p>
          Cards: {cards.value.length} ({getSizeInMB(cards.value)}mb)
        </p>
        <p>
          Tokens: {tokens.value.length} ({getSizeInMB(tokens.value)}mb)
        </p>
        <p>
          Tags: {tags.value.length} ({getSizeInMB(tags.value)}mb)
        </p>
        <p>
          Slots: {slots.value.length} ({getSizeInMB(slots.value)}mb)
        </p>
        <p>
          Categories: {categories.value.length} ({getSizeInMB(categories.value)}
          mb)
        </p>
        <p>
          Snapshots: {snapshots.value.length} ({getSizeInMB(snapshots.value)}mb)
        </p>
        <p>
          Layouts: {layouts.value.length} ({getSizeInMB(layouts.value)}mb)
        </p>
        <Button onClick={exportData}>Export Deck</Button>
      </FlexBox>
    </Card>
  );
}
