import FlexBox from 'UtilityComponents/FlexBox'
import HeadingCard from 'UtilityComponents/HeadingCard'
import React from 'react'
import ReorderCategoriesButton from './ReorderCategoriesButton'
import AddCategory from './AddCategory'

export default function Actions() {
  return (
      <HeadingCard title="Board Actions">
        <FlexBox gap flexStart>
          <AddCategory />
          <ReorderCategoriesButton />
        </FlexBox>
      </HeadingCard>
  )
}
