import { Button, Tooltip } from "@blueprintjs/core";
import { selectAllCards } from "Functions/Slot/selectAllCards";
import { updateSlot } from "Functions/Slot/updateSlot";
import { filteredCards } from "Signals/cards";
import React from "react";

export default function SelectAll({ slot }) {
  // const cards = filteredCards.value;

  // const handleClick = () => {
  //   updateSlot(
  //     {
  //       ...slot,
  //       cardIds: [...cards.map((card) => card.id)],
  //       // wildcard: false,
  //       enabled: true,
  //       multiSelect: true,
  //     },
  //   )
  // };
  return (
    <Tooltip content="Select all">
      <Button icon="multi-select" onClick={() => selectAllCards(slot)} />
    </Tooltip>
  );
}
