import React from "react";
import ListSlotsItem from "./ListSlotsItem";
import { slots } from "Signals/slots";
import FlexBox from "UtilityComponents/FlexBox";
import AddSlot from "Tiles/SingleCategory/AddSlot";
import { currentCategoryId } from "Signals/categories";
import EmptyCard from "UtilityComponents/EmptyCard";

export default function ListSlots({ category }) {

  return (
    <FlexBox flexStart gap alignStart>
      {slots.value
        .filter((slot) => slot.categoryId === category.id)
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((slot) => (
          <ListSlotsItem key={slot.id} slot={slot} category={category} />
        ))}
      {currentCategoryId.value === category.id && (
          <AddSlot categoryId={category.id} />
      )}
    </FlexBox>
  );
}
