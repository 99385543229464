import { computed, effect, signal } from "@preact/signals-react";

import { saveTagsToDb } from "Data/Tags/saveTagsToDb";
import generateGuid from "UtilityFunctions/generateGUID";

// State
export const tags = signal([]);

// Save to DB on change
effect(() => {
  if (!tags.value || tags.value.length === 0) return;
  saveTagsToDb(tags.value);
});

// Broadcast changes to other tabs
export const channel = new BroadcastChannel("tags");
let isUpdatingFromBroadcast = false;
let broadcastID = generateGuid();

effect(() => {
  if (!tags.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "tags",
    data: tags.value,
    id: broadcastID,
  });
});

// Listen for changes from other tabs
channel.onmessage = (event) => {
  if (event.data.type === "tags" && event.data.id !== broadcastID) {
    isUpdatingFromBroadcast = true;
    tags.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
};
