import { TextField } from "@mui/material";
import React from "react";
import { EditableText } from "@blueprintjs/core";
import { updateTag } from "Functions/Tag/updateTag";

export default function UpdateTagName({ tag }) {
  const [name, setName] = React.useState(tag.name);

  const handleUpdate = (e) => {
    updateTag({
      ...tag,
      name: name,
    });
  };

  return (
    <EditableText value={name} onChange={(value) => setName(value)} onConfirm={handleUpdate} />
  );
}
