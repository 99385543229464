import React from "react";
import { Card } from "@blueprintjs/core";
import FlexBox from "UtilityComponents/FlexBox";
import ListSlots from "./ListSlots/ListSlots";
import ListCategories from "./ListCategories/ListCategories";
import LoadHand from "./Actions/LoadHand";
import PTEXT from "UtilityComponents/PTEXT";

export default function Hand({ hand }) {
  return (
    <Card compact>
      <FlexBox gap column>
        <FlexBox>
          <LoadHand hand={hand} />
        </FlexBox>
        <ListSlots slots={hand.slots} />
        <ListCategories hand={hand} />
      </FlexBox>
    </Card>
  );
}
