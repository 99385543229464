import { Button, Icon, Spinner, Tooltip } from "@blueprintjs/core";
import { getApiStatus } from "API/getApiStatus";
import { addTile } from "Functions/Mosaic/addTile";
import { apiSettings } from "Signals/apiSettings";
import { apiConnected, apiProgress } from "Signals/appState";
import FlexBox from "UtilityComponents/FlexBox";
import React, { useEffect } from "react";

export default function APIStatus() {
  useEffect(() => {
    let intervalId;

    if (apiSettings.value.pollApi) {
      intervalId = setInterval(async () => {
        await getApiStatus();
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [apiSettings.value.pollApi]);

  const spinnerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
  };

  const handleClick = () => {
    addTile("APISettings");
  };

  return (
    <FlexBox padding gap>
      <Tooltip
        content={apiConnected.value ? "API Connected" : "API Disconnected"}
      >
        <Button
          onClick={handleClick}
          icon={apiConnected.value ? "tick-circle" : "offline"}
          intent={apiConnected.value ? "success" : "danger"}
        />
      </Tooltip>
      <Tooltip
        content={`Current Progress: ${(apiProgress.value * 100).toFixed(0)}%`}
      >
        <Spinner
          size={20}
          value={apiProgress.value}
          intent={apiConnected.value ? "success" : "danger"}
        />
      </Tooltip>
    </FlexBox>
  );
}
