import { Checkbox } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function PixelPerfect() {
  return (
    <FlexBox column>
      <p>Pixel Perfect</p>
      <Checkbox
        checked={apiSettings.value.cnPixelPerfect}
        onChange={(event) => {
          apiSettings.value = {
            ...apiSettings.value,
            cnPixelPerfect: event.target.checked,
          };
        }}
      />
    </FlexBox>
  );
}
