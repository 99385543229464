import React from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AddToken from "../AddToken";
import DraggableListTokensItem from "./DraggableListTokensItem";
import Loading from "UtilityComponents/Loading";
import FlexBox from "UtilityComponents/FlexBox";
import { Card } from "@blueprintjs/core";
import { tokens as stateTokens } from "Signals/tokens";
import { updateToken } from "Functions/Token/updateToken";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function ListTokens({ cardId }) {
  const tokens = stateTokens.value
    .filter((t) => t.cardId === cardId)
    .sort((a, b) => a.order - b.order);
  if (!tokens) return <Loading />;

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const tokenIds = tokens.map((token) => token.id);
      const oldIndex = tokenIds.indexOf(active.id);
      const newIndex = tokenIds.indexOf(over.id);
      const newTokensIds = arrayMove(tokenIds, oldIndex, newIndex);
      // Update order for each item
      newTokensIds.forEach((id, index) => {
        const token = tokens.find((t) => t.id === id);
        updateToken({ ...token, order: index });
      });
    }
  };

  return (
    <HeadingCard title="Tokens">
      <FlexBox column gap>
        <DndContext
          sensors={[
            {
              sensor: MouseSensor,
              options: { activationConstraint: { distance: 5 } },
            },
          ]}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={tokens}
            strategy={horizontalListSortingStrategy}
          >
            <FlexBox gap wrap>
              {tokens.map((token) => (
                <DraggableListTokensItem key={token.id} token={token} />
              ))}
            </FlexBox>
          </SortableContext>
        </DndContext>
      </FlexBox>
    </HeadingCard>
  );
}
