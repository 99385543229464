import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "./App.css";
import React, { useEffect } from "react";
import MyMosaic from "Mosaic/Mosaic";
import AddTileMenu from "Menu/AddTileMenu";
import FlexBox from "UtilityComponents/FlexBox";
import APIStatus from "Menu/APIStatus/APIStatus";
import DefaultLayoutsMenu from "Menu/DefaultLayouts/DefaultLayoutsMenu";
import SavedLayouts from "Menu/SavedLayouts/SavedLayouts";
import { getInitialData } from "Functions/Data/getInitialData";
import NavMenu from "Menu/NavMenu";
const effects = require("Signals/effects");

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    getInitialData().finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <div>Loading...</div>;

  const style = {
    display: "flex",
    // flexDirection: "column",
    height: "100vh",
    // margin: "0",
    // padding: "0",
    // width: "100vw",
  };

  return (
    <div
      // @ts-ignore
      style={style}
    >
      <FlexBox fullWidth alignStart>
        <NavMenu />
        {/* <APIStatus /> */}
        <MyMosaic />
      </FlexBox>
    </div>
  );
}

export default App;
