import { MenuItem, TagInput } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import FlexBox from "UtilityComponents/FlexBox";
import Loading from "UtilityComponents/Loading";
import React from "react";
import ToggleInclusiveTags from "./ToggleInclusiveTags";
import { tags } from "Signals/tags";

export default function Filter({ filter, setFilter }) {
  const filterTags = tags.value.filter((tag) => filter.tagIds.includes(tag.id));
  if (!tags || !filterTags) return <Loading />;

  const handleAdd = (tagId) => {
    const newTagIds = [...filter.tagIds, tagId];
    setFilter({ ...filter, tagIds: newTagIds });
  };

  const handleDelete = (tagId, index) => {
    const newTagIds = filter.tagIds.filter((id) => id !== tagId);
    setFilter({ ...filter, tagIds: newTagIds });
  };

  return (
    <FlexBox gap>
      <MultiSelect
        placeholder="Filter by tag"
        items={tags.value.filter(
          (tag) => !filterTags.some((filterTag) => filterTag.id === tag.id)
        )}
        tagRenderer={(tag) => tag.name}
        tagInputProps={{
          onRemove: (value, index) => {
            const tag = filterTags[index];
            handleDelete(tag.id, index);
          },
        }}
        onItemSelect={(tag) => {
          handleAdd(tag.id);
        }}
        selectedItems={filterTags}
        itemPredicate={(query, item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        }}
        itemRenderer={(item, { handleClick, modifiers }) => {
          return (
            <MenuItem
              key={item.id}
              text={item.name}
              onClick={handleClick}
              active={modifiers.active}
            />
          );
        }}
        popoverContentProps={{ className: "scrollable-dropdown" }}
        noResults={<MenuItem disabled={true} text="No results" />}
      />
      <ToggleInclusiveTags filter={filter} setFilter={setFilter} />
    </FlexBox>
  );
}
