import { HTMLSelect } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function InpaintFullRes() {

    const options = {
        0: "Whole picture",
        1: "Only masked",
    };

  return <FlexBox column>
    <p>Inpaint area</p>
    <HTMLSelect
        options={Object.entries(options).map(([value, label]) => ({
            value,
            label,
        }))}
        onChange={(event) => {
            apiSettings.value = {
                ...apiSettings.value,
                inpaint_full_res: parseInt(event.target.value)
            };
        }}
        value={apiSettings.value.inpaint_full_res}
    />
  </FlexBox>;
}
