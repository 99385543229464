import { Slider } from '@blueprintjs/core'
import { apiSettings } from 'Signals/apiSettings'
import FlexBox from 'UtilityComponents/FlexBox'
import PTEXT from 'UtilityComponents/PTEXT'
import React from 'react'

export default function ProcessorRes() {
  const [value, setValue] = React.useState(apiSettings.value.cnProcessorRes)
  return (
    <FlexBox column>
        <p>Processor Resolution</p>
        <Slider
            min={64}
            max={2047}
            stepSize={1}
            labelValues={[]}
            onRelease={(value) => {
                apiSettings.value = {
                ...apiSettings.value,
                cnProcessorRes: value,
                }
            }}
            onChange={(value) => {
                setValue(value);
            }}
            value={value}
            />
    </FlexBox>
  )
}
