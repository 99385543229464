import { currentLayout, layouts } from "Signals/layouts";
import generateGuid from "UtilityFunctions/generateGUID";

export const saveLayout = (name) => {
  layouts.value = [
    ...layouts.value,
    {
      id: generateGuid(),
      name: name,
      created: Date.now(),
      layout: JSON.stringify(currentLayout.value),
    },
  ];
};
