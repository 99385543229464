import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import {
  Card,
  Menu,
  MenuDivider,
  MenuItem,
  NonIdealState,
} from "@blueprintjs/core";
import { loadLayout } from "Functions/Layout/loadLayout";
import { layouts } from "Signals/layouts";

export default function ListLayouts() {
  const handleClick = (layout) => {
    loadLayout(layout);
  };
  return (
    <>
      {layouts.value.map((layout) => (
        <MenuItem
          key={layout.id}
          text={layout.name}
          onClick={() => handleClick(layout)}
        />
      ))}
    </>
  );
}
