import React from "react";
import { Button, InputGroup } from "@blueprintjs/core";
import FlexBox from "UtilityComponents/FlexBox";
import extractTokensFromString from "Functions/Token/extractTokensFromString";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function AddToken({ cardId }) {
  const [value, setValue] = React.useState("");

  const handleAdd = () => {
    if (!value) return;
    extractTokensFromString(cardId, value);
    setValue("");
  };
  return (
    <HeadingCard title="Add Token">
    <FlexBox>
      <InputGroup
        fill
        placeholder="Add Token(s)"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleAdd();
          }
        }}
        onBlur={handleAdd}
      />
      <Button icon="add" intent="primary" onClick={handleAdd}>
        Add
      </Button>
    </FlexBox>
    </HeadingCard>
  );
}
