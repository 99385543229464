import React from "react";
import UploadImage from "./UploadImage";
import ClearImage from "./ClearImage";

export default function CardImage({ card }) {
  const style = {
    position: "relative",
  };

  return (
    <div 
// @ts-ignore
    style={style}>
      <div>
        <UploadImage card={card} />
      </div>
      {card.image && <ClearImage card={card} />}
    </div>
  );
}
