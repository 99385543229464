import React from "react";
import UpdateName from "./UpdateName";
import EmptyTile from "UtilityComponents/EmptyTile";
import DeleteSlot from "./Actions/DeleteSlot";
import FlexCard from "UtilityComponents/FlexCard";
import ToggleEnabled from "./Actions/ToggleEnabled";
import ToggleMultiSelect from "./Actions/ToggleMultiSelect";
import ChangeCategory from "./Actions/ChangeCategory";
import FlexBox from "UtilityComponents/FlexBox";
import ToggleWildcard from "./Actions/ToggleWildcard";
import { currentSlot } from "Signals/slots";
import Actions from "./Actions/Actions";
import ListSelectedCards from "Tiles/ListCardsOptions/ListSelectedCards/ListSelectedCards";

export default function SingleSlot() {
  const slot = currentSlot.value;
  if (!slot) return <EmptyTile>No slot selected</EmptyTile>;

  return (
    <FlexBox gap padding column>
      <UpdateName slot={slot} />
      <ToggleEnabled slot={slot} />
      <ToggleMultiSelect slot={slot} />
      <ToggleWildcard slot={slot} />
      <ChangeCategory slot={slot} />
      <ListSelectedCards slot={slot} />
      <Actions slot={slot} />
    </FlexBox>
  );
}
