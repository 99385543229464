import { SwitchCard } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import React from "react";

export default function ToggleAdetailer() {
  return (
    <SwitchCard
      checked={apiSettings.value.adetailer_enable}
      onChange={() => {
        apiSettings.value = {
          ...apiSettings.value,
          adetailer_enable: !apiSettings.value.adetailer_enable,
        };
      }}
    >
      Adetailer
    </SwitchCard>
  );
}
