import { Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React, { useState } from "react";

export default function InpaintFullResPadding() {
  const [value, setValue] = useState(
    apiSettings.value.inpaint_full_res_padding
  );
  return (
    <FlexBox column>
      <p>Only masked padding</p>
      <Slider
        min={0}
        max={256}
        stepSize={1}
        labelValues={[]}
        onRelease={(value) => {
          apiSettings.value = {
            ...apiSettings.value,
            inpaint_full_res_padding: value,
          };
        }}
        onChange={(value) => {
          setValue(value);
          apiSettings.value.inpaint_full_res_padding = value;
        }}
        value={value}
      />
    </FlexBox>
  );
}
