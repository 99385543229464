import { apiSettings } from "Signals/apiSettings";
import { apiConnected, apiProgress, apiStatus } from "Signals/appState";
import axios from "axios";

export const getApiStatus = async () => {
  const url = `${apiSettings.value.apiUrl}/sdapi/v1/progress`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    if (data) {
      apiStatus.value = "Connected";
      apiConnected.value = true;
      apiProgress.value = data.progress;
      // apiMessage.value = data.message;
    }
  } catch (error) {
    // console.log(error)
    apiStatus.value = "Error fetching data from API";
    apiConnected.value = false;
  }
};
