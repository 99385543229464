import { Button, Card, Text, TextArea } from "@blueprintjs/core";
import { Tooltip } from "@mui/material";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function Prompt({ prompt }) {
  if (!prompt) return null;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };
  return (
    <Card>
      <FlexBox column gap>
        <FlexBox gap flexStart>
          <PTEXT>Prompt</PTEXT>
          <Tooltip title="Copy to clipboard">
            <Button
              icon="clipboard"
              onClick={() => copyToClipboard(prompt.positive)}
            />
          </Tooltip>
        </FlexBox>
        <TextArea fill readOnly autoResize value={prompt.positive} />
        <FlexBox gap flexStart>
          <PTEXT>Negative Prompt</PTEXT>
          <Tooltip title="Copy to clipboard">
            <Button
              icon="clipboard"
              onClick={() => copyToClipboard(prompt.negative)}
            />
          </Tooltip>
        </FlexBox>
        <TextArea fill readOnly autoResize value={prompt.negative} />
      </FlexBox>
    </Card>
  );
}
