const { effect } = require("@preact/signals-react");
const { cards } = require("./cards");
const { updatePrompt } = require("Functions/Prompt/updatePrompt");
const { slots } = require("./slots");
const { categories } = require("./categories");
const { tokens } = require("./tokens");

// Update prompt on deck changes
effect(() => {
  if (cards.value || slots.value || tokens.value || categories.value) {
    updatePrompt();
  }
});
