import { SwitchCard } from '@blueprintjs/core'
import { updateSlot } from 'Functions/Slot/updateSlot'
import React from 'react'

export default function ToggleMultiSelect({slot}) {
  return (
    <SwitchCard
        checked={slot.multiSelect}
        // onChange={() => updateSlot(slot.id, { multiSelect: !slot.multiSelect })}
        onChange={() => updateSlot({ ...slot, multiSelect: !slot.multiSelect })}
        >
        Multi-Select
        </SwitchCard>
  )
}
