import { categories } from "Signals/categories";
import generateGuid from "UtilityFunctions/generateGUID";

export default function addCategory(name) {
  categories.value = [
    ...categories.value,
    {
      id: generateGuid(),
      name: name || "New Category",
      enabled: true,
      color: "#000000",
      displayOrder: 0,
      renderOrder: 0,
    },
  ];
}
