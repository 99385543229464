import React from "react";

export default function FlexBox(props) {
  let style = {
    // display: props.fullWidthColumn ? "block" : "flex",
    // width: props.width ? props.width : "auto",
    width: props.fullWidth ? "100%" : "", // Is this safe?
    display: "flex",
    height: props.fullHeight ? "100%" : "auto",
    flexWrap: props.wrap ? "wrap" : "nowrap",
    flexDirection: props.column ? "column" : "row",
    gap: props.gap ? "0.5rem" : props.bigGap ? "1rem" : 0,
    marginTop: props.mt ? "0.5rem" : 0,
    marginBottom: props.mb ? "0.5rem" : 0,
    padding: props.padding ? "0.5rem" : 0,
    margin: props.margin ? "0.5rem" : 0,
    justifyContent: props.center
      ? "center"
      : props.spaceBetween
      ? "space-between"
      : props.spaceAround
      ? "space-around"
      : props.spaceEvenly
      ? "space-evenly"
      : props.flexStart
      ? "flex-start"
      : props.flexEnd
      ? "flex-end"
      : "center",
    alignItems: props.alignStart ? "flex-start" : props.alignEnd ? "flex-end" : "center",
  };

  // Apply any styles from props.style
  if (props.style) {
    style = { ...style, ...props.style };
  }

  return (
    <div
    onClick={props.onClick}
    onDoubleClick={props.onDoubleClick}
      // @ts-ignore
      style={style}
      className={`${props.className ? "props.className" : ""} ${
        props.column ? "fullWidthChildren" : ""
      }`}
    >
      {props.children}
    </div>
  );
}
