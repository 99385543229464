import { categories } from "Signals/categories";

export const updateCategory = (category) => {
  categories.value = categories.value.map((c) => {
    if (c.id === category.id) {
      return category;
    } else {
      return c;
    }
  });
};
