import { Button } from "@blueprintjs/core";
import { cloneCard } from "Functions/Card/cloneCard";
import React from "react";

export default function CloneCard({card}) {
  return (
    <Button
      icon="duplicate"
      onClick={() => {
        cloneCard(card);
      }}
    >
      Clone Card
    </Button>
  );
}
