import { current } from "immer";
import { addToken } from "./addToken";

export default function extractTokensFromString(cardId, string) {
  // Split on parentheses
  const parens = string.split("(");

  parens.forEach((paren) => {
    // Split on comma
    const tokens = paren.split(",");
    tokens.forEach((token) => {
      let power = null;
      let lora = false;
      // If token is blank or only whitespace, skip
      if (!token.trim()) return;
      // Trim whitespace
      token = token.trim();
      // If token contains <lora, extract value between colons
      if (token.includes("<lora")) {
        power = token.split(":")[2];
        lora = true;
        token = token.split(":")[1];
      }
      // If token contains a colon, extract value between colon and closing paren
      else if (token.includes(":")) {
        power = token.split(":")[1].split(")")[0];
        token = token.split(":")[0];
        // Remove parens from power
        power = power.replace(")", "");
      }
      // Remove parens from token
      token = token.replace(")", "");
      token = token.replace("(", "");
      addToken(cardId, token, power, lora);
      // Set as current token 
    });
  });
}
