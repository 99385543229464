import { Card, Checkbox, Divider, Tooltip } from "@blueprintjs/core";
import React from "react";
import ListSlots from "./ListSlots/ListSlots";
import AddSlot from "Tiles/SingleCategory/AddSlot";
import FlexBox from "UtilityComponents/FlexBox";
import { currentCategoryId } from "Signals/categories";
import { updateCategory } from "Functions/Category/updateCategory";
import TitleText from "UtilityComponents/TitleText";
import PTEXT from "UtilityComponents/PTEXT";
import { settings } from "Signals/settings";
import BigText from "UtilityComponents/BigText";
import EmptyCard from "UtilityComponents/EmptyCard";
import { addTile } from "Functions/Mosaic/addTile";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function ListCategoriesItem({ category }) {
  const handleClick = () => {
    currentCategoryId.value = category.id;
    addTile("SingleCategory");
  };

  const handleDoubleClick = () => {
    toggleEnabled();
  };

  const handleCheckBoxChange = (e) => {
    toggleEnabled();
  };
  const toggleEnabled = () => {
    updateCategory({
      ...category,
      enabled: !category.enabled,
    });
  };

  const titleStyle = {
    cursor: "pointer",
    marginBottom: "0.5rem",
  };
  const style = {
    width: "100%",
    overflow: "auto",
    // overFlowX: "auto",
    padding:'0.5rem'
  };

  return (
      <Card
        className={!category.enabled && "disabled"}
        style={style}
        onDoubleClick={handleDoubleClick}
      >
        <FlexBox
          flexStart
          gap
          alignStart
          column
          handleDoubleClick={handleDoubleClick}
        >
          <FlexBox flexStart>
            <Checkbox
              checked={category.enabled}
              onChange={handleCheckBoxChange}
            />
            <Tooltip content="Open category settings" hoverOpenDelay={500}>
              <FlexBox flexStart style={titleStyle} onClick={handleClick}>
                <PTEXT uppercase>{category.name}</PTEXT>
              </FlexBox>
            </Tooltip>
          </FlexBox>
          <Divider className="m-0 p-0" />
          <ListSlots category={category} />
        </FlexBox>
      </Card>
  );
}
