import { Card, HTMLSelect } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function SecondaryCheckpoint() {
  return (
    <HeadingCard title="Secondary Checkpoint">
      <HTMLSelect
        options={apiSettings.value.checkpoints}
        onChange={(event) => {
          apiSettings.value = {
            ...apiSettings.value,
            secondaryCheckpoint: event.target.value,
          };
        }}
        value={apiSettings.value.secondaryCheckpoint}
      />
    </HeadingCard>
  );
}
