import { EditableText, H3, H5, InputGroup } from "@blueprintjs/core";
import { updateToken } from "Functions/Token/updateToken";
import { set } from "lodash";
import React, { useEffect } from "react";

export default function UpdateTokenValue({ token }) {
  const [value, setValue] = React.useState(token.value);
  useEffect(() => {
    setValue(token.value);
  }, [token.value]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleUpdate = () => {
    updateToken({ ...token, value });
  };

  return (
    <InputGroup
      disabled={!token.enabled}
      fill
      value={value}
      onChange={handleChange}
      onBlur={handleUpdate}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleUpdate();
        }
      }}
    />
    // <div style={{width:'100%'}}>
    //   <p className={`m-1 ${!token.enabled && "disabled"}`} style={{display:'flex'}}>
    //     <EditableText
    //       selectAllOnFocus
    //       multiline
    //       disabled={!token.enabled}
    //       value={value}
    //       onChange={(value) => setValue(value)}
    //       onBlur = {handleChange}
    //       onConfirm = {handleChange}
    //     />
    //   </p>
    // </div>
  );
}
