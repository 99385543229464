import { getCnModels } from "API/getCnModels";
import getCheckpoints from "API/getCheckpoints";
import { getSamplers } from "API/getSamplers";
import { getUpscalers } from "API/getUpscalers";
import { getCnModules } from "API/getCnModules";

export const refreshData = () => {
    getCheckpoints();
    getSamplers();
    getUpscalers();
    getCnModels();
    getCnModules();
}