import { buildCardPrompt } from "Functions/Card/buildCardPrompt";
import { getCardsFromHandInRenderOrder } from "Functions/Snapshot/getCardsFromHandInRenderOrder";
import { tokens } from "Signals/tokens";

export const buildPromptFromHand = (hand) => {
  const prompt = {
    positive: "",
    negative: "",
  };
  if (!hand) return prompt;
  let cards = getCardsFromHandInRenderOrder(hand);
  cards.forEach((card) => {
    const filteredTokens = tokens.value
      .filter((token) => token.cardId === card.id)
      .sort((a, b) => a.order - b.order);
    const cardPrompt = buildCardPrompt(filteredTokens);

    // Add card prompt to overall prompt
    if (cardPrompt.positive) prompt.positive += cardPrompt.positive + ", ";
    // prompt.positive += cardPrompt.positive;
    if (cardPrompt.negative) prompt.negative += cardPrompt.negative + ", ";
    // if (cardPrompt.negative) prompt.negative += cardPrompt.negative;
  });
  return prompt;
};
