import { Button } from "@blueprintjs/core";
import { updatePrompt } from "Functions/Prompt/updatePrompt";
import React from "react";

export default function UpdatePrompt() {
  const handleClick = () => {
    updatePrompt();
  };
  return <Button onClick={handleClick}>Regenerate Prompt</Button>;
}
