import { HTMLSelect } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function ResizeMode() {
  const options = {
    0: "Just Resize",
    1: "Crop and Resize",
    2: "Resize and Fill",
  };

  return (
    <FlexBox column>
      <p>Resize Mode</p>
      <HTMLSelect
        options={Object.entries(options).map(([value, label]) => ({
          value,
          label,
        }))}
        onChange={(event) => {
          apiSettings.value = {
            ...apiSettings.value,
            cnResizeMode: parseInt(event.target.value),
          };
        }}
        value={apiSettings.value.cnResizeMode}
      />
    </FlexBox>
  );
}
