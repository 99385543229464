import React from "react";
import SearchTerm from "./SearchTerm";
import Tags from "./Tags";
import Sort from "./Sort";
import ToggleInclusiveTags from "./ToggleInclusiveTags";
import FlexBox from "UtilityComponents/FlexBox";
import { Card } from "@blueprintjs/core";
import { currentSlot } from "Signals/slots";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function Filter() {
  const slot = currentSlot.value;
  if (!slot) return null;

  return (
    <HeadingCard compact title="Filter">
      <FlexBox gap wrap>
        <SearchTerm />
        <Tags />
        <ToggleInclusiveTags />
        <Sort/>
      </FlexBox>
    </HeadingCard>
  );
}
