import { Slider } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function HRSteps() {
  const [value, setValue] = React.useState(apiSettings.value.hr_steps);
  return (
    <FlexBox column>
      <p>Steps</p>
      <Slider
        min={1}
        max={30}
        stepSize={1}
        labelValues={[]}
        onRelease={(value) => {
          apiSettings.value = {
            ...apiSettings.value,
            hr_steps: value,
          };
        }}
        onChange={(value) => {
          setValue(value);
        }}
        value={value}
      />
    </FlexBox>
  );
}
