import { Button, Tooltip } from "@blueprintjs/core";
import { refreshData } from "Functions/API/refreshData";
import FlexBox from "UtilityComponents/FlexBox";
import HeadingCard from "UtilityComponents/HeadingCard";
import React from "react";

export default function RefreshData() {
  const handleClick = () => {
    refreshData();
  };
  return (
    <HeadingCard title="API Data">
    <FlexBox>
      <Tooltip content="Refresh available checkpoints, samplers, and upscalers from API">
      <Button icon="refresh" onClick={handleClick}>
        Refresh Data
      </Button>
      </Tooltip>
    </FlexBox>
    </HeadingCard>
  );
}
