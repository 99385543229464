import { apiSettings } from "Signals/apiSettings";
import axios from "axios";

export const getSamplers = async () => {
  const url = `${apiSettings.value.apiUrl}/sdapi/v1/samplers`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    apiSettings.value = {
      ...apiSettings.value,
      samplers: data.flatMap((sampler) => sampler.name),
    };
    // return data;
  } catch (error) {
    // state.apiStatus = "Error fetching data from API";
  }
};
