import { currentCardId } from "Signals/cards";
import { currentTokenId, tokens } from "Signals/tokens";

export const setCurrentCardId = (id) => {
  currentCardId.value = id;

  // Also set current token to first token in card
  currentTokenId.value = tokens.value.filter(
    (t) => t.cardId === currentCardId.value
  )[0]?.id;
};
