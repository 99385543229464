import { apiSettings } from "Signals/apiSettings";
import axios from "axios";

export const getCnModules = async () => {
  const url = `${apiSettings.value.apiUrl}/controlnet/module_list`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    if (data.module_list.length === 0) {
      return;
    }
    apiSettings.value = {
      ...apiSettings.value,
      cnModules: data.module_list,
    };
  } catch (error) {
    // state.apiStatus = "Error fetching data from API";
  }
};
