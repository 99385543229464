import { Card, HTMLTable, TextArea } from "@blueprintjs/core";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function Info({ snapshot }) {
  if (!snapshot || !snapshot.info) return null;
  const info = JSON.parse(snapshot.info);
  return (
    <Card>
      <FlexBox column gap>
        <HTMLTable bordered>
          <thead>
            <tr>
              <th>Property</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sampler</td>
              <td>{info.sampler_name}</td>
            </tr>
            <tr>
              <td>Model</td>
              <td>{info.sd_model_name}</td>
            </tr>
            <tr>
              <td>Refiner</td>
              <td>{info.extra_generation_params.Refiner}</td>
            </tr>
          </tbody>
        </HTMLTable>
        <TextArea fill value={snapshot.info} readOnly />
      </FlexBox>
    </Card>
  );
}
