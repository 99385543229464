import { computed, effect, signal } from "@preact/signals-react";
import { saveTokensToDb } from "Data/Tokens/saveTokensToDb";
import { cardContainsLora } from "Functions/Card/cardContainsLora";
import generateGuid from "UtilityFunctions/generateGUID";
import { currentCard, currentCardId } from "./cards";
import { updateCard } from "Functions/Card/updateCard";

// State
export const tokens = signal([]);
export const currentTokenId = signal(null);
export const currentToken = computed(() => {
  const token = tokens.value.filter((s) => s.id === currentTokenId.value)[0];
  return token;
});

// Save to DB on change
effect(() => {
  if (!tokens.value || tokens.value.length === 0) return;
  saveTokensToDb(tokens.value);
});

// Broadcast changes to other tabs
export const channel = new BroadcastChannel("tokens");
let isUpdatingFromBroadcast = false;
let broadcastID = generateGuid();

effect(() => {
  if (!tokens.value || isUpdatingFromBroadcast) return;
  broadcastID = generateGuid();
  channel.postMessage({
    type: "tokens",
    data: tokens.value,
    id: broadcastID,
  });
});

channel.onmessage = (event) => {
  if (event.data.type === "tokens" && event.data.id !== broadcastID) {
    isUpdatingFromBroadcast = true;
    tokens.value = event.data.data;
    isUpdatingFromBroadcast = false;
  }
};
