import { tags } from "Signals/tags";
import generateGuid from "UtilityFunctions/generateGUID";

export const addTag = (name) => {
  const id = generateGuid();
  // If tag with this name already exists, return the id of that instead
  const existingTag = tags.value.find((tag) => tag.name === name);
  if (existingTag) {
    return existingTag.id;
  }
  tags.value = [...tags.value, { id: id, name: name, created: Date.now() }];
  // Sort tags alphabetically
  tags.value = [...tags.value.sort((a, b) => a.name.localeCompare(b.name))];
  return id;
};
