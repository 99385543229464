import FlexBox from 'UtilityComponents/FlexBox'
import React from 'react'
import GridWidth from './GridWidth'

export default function Actions() {
  return (
    <FlexBox spaceBetween>
        <div>
        </div>
        <GridWidth />
    </FlexBox>
  )
}
