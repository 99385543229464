import React, { useState } from "react";
import Actions from "./Actions/Actions";
import { Card, NonIdealState } from "@blueprintjs/core";
import EmptyTile from "UtilityComponents/EmptyTile";
import FlexBox from "UtilityComponents/FlexBox";
import Filter from "./Filter/Filter";
import { getFilteredSnapshots } from "Functions/Snapshot/getFilteredSnapshots";
import { loadHand } from "Functions/Snapshot/loadHand";
import { currentSnapshotId } from "Signals/snapshots";
import { settings } from "Signals/settings";

export default function ListSavedSnapshots() {
  const [filter, setFilter] = useState({
    tagIds: [],
    inclusiveTags: false,
  }); // [tagId, setTagId
  const snapshots = getFilteredSnapshots(filter).sort((a, b) => {
    return b.created - a.created;
  })
  // if (!snapshots || snapshots.length === 0)
  //   return <EmptyTile>No Saved Snapshots</EmptyTile>;
  if(!snapshots) return <EmptyTile>No Saved Snapshots</EmptyTile>

  const handleClick = (snapshotId) => {
    currentSnapshotId.value = snapshotId;
  };

  const handleDoubleClick = (snapshot) => {
    loadHand(snapshot.hand);
  };

  const getStyle = () => {
    return {
      display: "grid",
      gridTemplateColumns: `repeat(auto-fill, minmax(${
        settings.value.listSavedSnapshotsGridWidth || 300
      }px, 1fr))`,
    };
  };

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
    cursor: "pointer",
  };

  return (
    <FlexBox column padding gap>
      <Card>
        <FlexBox spaceBetween>
          <Filter filter={filter} setFilter={setFilter} />
          <Actions />
        </FlexBox>
      </Card>
      {snapshots.length === 0 && <NonIdealState icon="search" title="No Snapshots Found"/>}
      <div style={getStyle()}>
        {snapshots.map((snapshot, index) => (
          <div key={index}>
            {!snapshot.image && (
              <Card
                key={index}
                interactive
                onClick={() => handleClick(snapshot.id)}
                onDoubleClick={() => handleDoubleClick(snapshot)}
              >
                <p>{snapshot.id}</p>
                <p>No image</p>
              </Card>
            )}
            {snapshot.image && (
              <img
                onClick={() => handleClick(snapshot.id)}
                onDoubleClick={() => handleDoubleClick(snapshot)}
                key={index}
                style={imageStyle}
                // src={`data:image/jpg;base64,${snapshot.image?.images[0]}`}
                src={snapshot.image.images ? `data:image/jpg;base64,${snapshot.image?.images[0]}` : snapshot.image}
              />
            )}
          </div>
        ))}
      </div>
    </FlexBox>
  );
}
