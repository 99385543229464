import { cnImage } from "Signals/appState";
import RenderImage from "UtilityComponents/RenderImage";
import React from "react";

export default function ShowImage() {
  if(!cnImage.value) return null;

  let src = "";
  if(cnImage.value.startsWith("data:image")) {
    src = cnImage.value;
  } else {
    src = `data:image/jpg;base64,${cnImage.value}`;
  }

  const style = {
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%",
    objectFit: "contain",
  };
  // @ts-ignore
  return <img style={style} src={src} />;
}
