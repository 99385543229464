import { HTMLSelect, InputGroup } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import PTEXT from "UtilityComponents/PTEXT";
import React from "react";

export default function Sampler() {
  return (
    <FlexBox column>
      <p>Sampling Method</p>
      <HTMLSelect
        options={apiSettings.value.samplers}
        value={apiSettings.value.sampler}
        onChange={(event) => {
          apiSettings.value = {
            ...apiSettings.value,
            sampler: event.target.value,
          };
        }}
      >
        {/* {settings.samplers?.map((sampler) => {
        return (
          <option key={sampler} value={sampler}>
            {sampler}
          </option>
        );
      })} */}
      </HTMLSelect>
    </FlexBox>
  );
}
