import { Card, EditableText, H2 } from "@blueprintjs/core";
import { updateCategory } from "Functions/Category/updateCategory";
import PTEXT from "UtilityComponents/PTEXT";
import TitleText from "UtilityComponents/TitleText";
import React from "react";

export default function UpdateName({ category }) {
  return (
    <Card>
      <TitleText>
        <EditableText
          value={category.name}
          placeholder="Name"
          onChange={(value) => {
            updateCategory({ ...category, name: value });
          }}
          selectAllOnFocus={true}
        />
      </TitleText>
    </Card>
  );
}
