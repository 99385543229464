import { Card, CardList, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import React from "react";
import FlexBox from "UtilityComponents/FlexBox";
import { textFieldClasses } from "@mui/material";
import { loadDefaultLayout } from "Functions/Mosaic/loadDefaultLayout";

export default function DefaultLayoutsMenu() {
  const handleClick = (layoutName) => {
    loadDefaultLayout(layoutName);
  };

  const MyMenuItem = ({ id, text }) => {
    return (
      <>
        {/* <MenuDivider /> */}
        <MenuItem onClick={() => handleClick(id)} text={text} />
      </>
    );
  };

  return (
    <>
      <MyMenuItem id="build" text="BUILD PROMPT" />
      <MyMenuItem id="generate" text="GENERATE IMAGES" />
      <MyMenuItem id="controlNet" text="GENERATE CONTROLNET" />
      <MyMenuItem id="viewSaved" text="SNAPSHOTS" />
    </>
  );
}
