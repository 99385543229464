import React from "react";
import { Button, Tooltip } from "@blueprintjs/core";
import { deleteSlot } from "Functions/Slot/deleteSlot";

export default function DeleteSlot({ slot }) {
  return (
    <Tooltip content="Delete slot">
      <Button
        icon="trash"
        intent="danger"
        onClick={() => {
          deleteSlot(slot.id);
        }}
      />
    </Tooltip>
  );
}
