import FlexBox from "UtilityComponents/FlexBox";
import React from "react";
import CategoryItem from "./CategoryItem";
import EmptyTile from "UtilityComponents/EmptyTile";
import UnlockAll from "./Actions/UnlockAll";
import { Card } from "@blueprintjs/core";
import { categories } from "Signals/categories";

export default function LockSlots() {
  return (
    <FlexBox column gap padding>
      <Card>
        <p>
          Locking slots prevents their state from changing when loading a hand
          from a snapshot.
        </p>
        <p>
          This can be useful if you want to retain a particular card while
          loading different hands.
        </p>
      </Card>
      <UnlockAll />
      {categories.value
        .slice()
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((category) => (
          <CategoryItem key={category.id} category={category} />
        ))}
    </FlexBox>
  );
}
