import ListCards from "Tiles/ListCards/ListCards";
import ListTags from "Tiles/ListTags/ListTags";
import React from "react";
import SingleCard from "Tiles/SingleCard/SingleCard";
import SingleSlot from "Tiles/SingleSlot/SingleSlot";
import UIOptions from "Tiles/UIOptions/UIOptions";
import ListCategories from "Tiles/ListCategories/ListCategories";
import SingleCategory from "Tiles/SingleCategory/SingleCategory";
import GenerateImage from "Tiles/GenerateImage/GenerateImage";
import ManageData from "Tiles/ManageData/ManageData";
import ListSnapshots from "Tiles/ListSnapshots/ListSnapshots";
import SingleSnapshot from "Tiles/SingleSnapshot/SingleSnapshot";
import ListSavedSnapshots from "Tiles/ListSavedSnapshots/ListSavedSnapshots";
import LockSlots from "Tiles/LockSlots/LockSlots";
import Layouts from "Tiles/ListLayouts/Layouts";
import ReorderCategories from "Tiles/ReorderCategories/ReorderCategories";
import GenerationSettings from "Tiles/GenerationSettings/GenerationSettings";
import APISettings from "Tiles/APISettings/APISettings";
import ControlNet from "Tiles/ControlNet/ControlNet";
import Prompt from "Tiles/Prompt/Prompt";
import ListCardsOptions from "Tiles/ListCardsOptions/ListCardsOptions";
import ListCategoriesCompact from "Tiles/ListCategoriesCompact/ListCategories";
const ELEMENTS = [
  {
    id: "ListCards",
    title: "Deck",
    element: <ListCards />,
  },
  {
    id: "ListCardsOptions",
    title: "Deck Filter",
    element: <ListCardsOptions />,
  },
  {
    id: "SingleCard",
    title: "Card",
    element: <SingleCard />,
    width: 20,
  },
  {
    id: "ListTags",
    title: "Tags",
    element: <ListTags />,
  },
  {
    id: "SingleSlot",
    title: "Slot",
    element: <SingleSlot />,
    // width:10,
  },
  {
    id: "UIOptions",
    title: "UI Options",
    element: <UIOptions />,
  },
  {
    id: "ListCategories",
    title: "Prompt Board",
    element: <ListCategories />,
  },
  {
    id: "ListCategoriesCompact",
    title: "Prompt Board Compact",
    element: <ListCategoriesCompact />,
  },
  {
    id: "SingleCategory",
    title: "Category",
    element: <SingleCategory />,
  },
  {
    id: "ReorderCategories",
    title: "Reorder Categories",
    element: <ReorderCategories />,
  },

  {
    id: "GenerateImage",
    title: "Generate Image",
    element: <GenerateImage />,
    width: 10,
  },
  {
    id: "ListSnapshots",
    title: "List Snapshots",
    element: <ListSnapshots />,
  },
  {
    id: "SavedSnapshots",
    title: "Saved Snapshots",
    element: <ListSavedSnapshots />,
  },
  {
    id: "SingleSnapshot",
    title: "Current Snapshot",
    element: <SingleSnapshot />,
  },
  {
    id: "LockSlots",
    title: "Lock Slots",
    element: <LockSlots />,
    width: 10,
  },
  {
    id: "APISettings",
    title: "API Settings",
    element: <APISettings />,
  },
  {
    id: "GenerationSettings",
    title: "Image Generation Settings",
    element: <GenerationSettings />,
  },
  {
    id: "ControlNet",
    title: "ControlNet",
    element: <ControlNet />,
  },
  { id: "Layouts", title: "Layouts", element: <Layouts />, width: 10 },
  {
    id: "ManageData",
    title: "Manage Data",
    element: <ManageData />,
  },
  {
    id: "Prompt",
    title: "Prompt",
    element: <Prompt />,
  },
];

export default ELEMENTS;
