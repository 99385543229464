import { addCard } from "./addCard";
import { cloneToken } from "./cloneToken";
import { tokens as stateTokens } from "Signals/tokens";
import { deleteToken } from "Functions/Token/deleteToken";
import { cards, currentCardId } from "Signals/cards";

export const cloneCard = (card) => {
  const id = addCard(card.name + " (copy)");
  console.log("id", id);
  // Copy over tags
  const newCard = cards.value.find((c) => c.id === id);
  newCard.tagIds = [...card.tagIds];
  // Clone tokens and add to new card
  const tokens = stateTokens.value.filter((t) => t.cardId === card.id);
  tokens.forEach((token) => {
    cloneToken(token, id);
  });
  // // Remove the token which gets automaitcally added when a card is created
  const token = stateTokens.value.find(
    (t) => t.cardId === id && t.value === newCard.name
  );
  if (token) deleteToken(token.id);
  //   // Set as current card
  currentCardId.value = id;
};
