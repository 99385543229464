import { Card } from "@blueprintjs/core";
import { settings } from "Signals/settings";
import React from "react";

export default function EmptyCard(props) {
  const style = {
    width: settings.value.cardWidth,
    height: settings.value.cardHeight,
    opacity: props.disabled ? 0.2 : 1,
  };

  return <Card style={style}>
    {props.children}
  </Card>;
}
