import React from "react";
import ListCards from "./ListCards/ListCards";
import { Card, Checkbox, H3, H4 } from "@blueprintjs/core";
import FlexBox from "UtilityComponents/FlexBox";
import { currentSlotId } from "Signals/slots";
import { useDeepSignal } from "deepsignal/react";
import { updateSlot } from "Functions/Slot/updateSlot";
import { updateCategory } from "Functions/Category/updateCategory";
import { settings } from "Signals/settings";
import { set } from "lodash";
import { currentCategoryId } from "Signals/categories";
import EmptyCard from "UtilityComponents/EmptyCard";

export default function ListSlotsItem({ slot, category }) {
  const [showCheckbox, setShowCheckbox] = React.useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    currentSlotId.value = slot.id;
    currentCategoryId.value = category.id;
  };

  const handleDoubleClick = (e) => {
    e.stopPropagation();
    handleToggleEnabled();
  };

  const handleHoverIn = () => {
    setShowCheckbox(true);
  };
  const handleHoverOut = () => {
    setShowCheckbox(false);
  };

  const isDisabled = () => {
    return !slot.enabled || !category.enabled;
  };

  const titleStyle = {
    maxWidth:
      currentSlotId.value === slot.id || showCheckbox
        ? settings.value.cardWidth - 50
        : settings.value.cardWidth - 20,
    // maxWidth: settings.value.cardWidth - 50,
    margin: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textTransform: "uppercase",
  };

  const handleToggleEnabled = () => {
    // We check the CURRENT value to determine what to do with category, before updating slot
    if (!slot.enabled) {
      updateCategory({
        ...category,
        enabled: true,
      });
    }
    updateSlot({
      ...slot,
      enabled: !slot.enabled,
    });
  };

  const cardStyle = {
    padding: 0,
    minWidth: settings.value.cardWidth,
  };

  return (
    <Card
      style={cardStyle}
      interactive
      className={isDisabled() && "disabled"}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}
    >
      <FlexBox column>
        <FlexBox flexStart padding spaceBetween>
          <p
            // @ts-ignore
            style={titleStyle}
          >
            {slot.name}
          </p>
          {(currentSlotId.value === slot.id || showCheckbox) && (
            <div onClick={(e) => e.stopPropagation()}>
              <Checkbox
                className="m-0"
                checked={slot.enabled}
                onClick={handleToggleEnabled}
              />
            </div>
          )}
        </FlexBox>
        {!slot.wildcard && (
          <ListCards
            cardIds={slot.cardIds}
            disabled={!slot.enabled || !category.enabled}
          />
        )}
        {slot.wildcard && (
          <EmptyCard disabled={!slot.enabled || !category.enabled}>
            <FlexBox fullHeight>
              <p>
                <strong>Wildcard</strong>
              </p>
            </FlexBox>
          </EmptyCard>
        )}
      </FlexBox>
    </Card>
  );
}
