import { Card } from "@blueprintjs/core";
import React from "react";
import ListSlotsItem from "./ListSlotsItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { slots as stateSlots } from "Signals/slots";
import { updateSlot } from "Functions/Slot/updateSlot";
import HeadingCard from "UtilityComponents/HeadingCard";

export default function ListSlots({ categoryId }) {
  const slots = stateSlots.value.filter(
    (slot) => slot.categoryId === categoryId
  );

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedSlots = Array.from(slots);
    const [reorderedSlot] = reorderedSlots.splice(result.source.index, 1);
    reorderedSlots.splice(result.destination.index, 0, reorderedSlot);
    // Update displayOrder for each slot
    reorderedSlots.forEach((slot, index) => {
      updateSlot({
        ...slot,
        displayOrder: index,
      });
    });
  };

  return (
    <HeadingCard title="Reorder Slots">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="slots">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {slots
                ?.sort((a, b) => a.displayOrder - b.displayOrder)
                .map((slot, index) => (
                  <Draggable
                    key={slot.id}
                    draggableId={slot.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <ListSlotsItem slot={slot} />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </HeadingCard>
  );
}
