import { Card } from "@blueprintjs/core";
import FlexCard from "UtilityComponents/FlexCard";
import React from "react";
import ToggleLock from "./Actions/ToggleLock";
import PTEXT from "UtilityComponents/PTEXT";

export default function SlotItem({ slot }) {
  return (
    <FlexCard compact spaceBetween padding>
      <PTEXT>{slot.name}</PTEXT>
      <div>
        <ToggleLock slot={slot}/>
      </div>
    </FlexCard>
  );
}
