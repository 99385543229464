import { H2, NonIdealState } from "@blueprintjs/core";
import React from "react";

export default function EmptyTile({ children, description = "" }) {
  return (
    // <H2
    //   style={{
    //     display: "flex",
    //     margin: 0,
    //     justifyContent: "center",
    //     alignItems: "center",
    //     height: "100%",
    //   }}
    // >
    //   {children}
    // </H2>
    <NonIdealState
      title={children}
      icon="search"
      >{description}</NonIdealState>
  );
}
