import { Checkbox, SwitchCard } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import React from "react";

export default function ToggleMask() {
  const toggleMask = () => {
    apiSettings.value = {
      ...apiSettings.value,
      useMask: !apiSettings.value.useMask,
    };
  };
  return (
    <SwitchCard
      checked={apiSettings.value.useMask}
      onChange={toggleMask}
    >Use Mask</SwitchCard>
  );
}
