import React from "react";
import ExportData from "./ExportData";
import ImportData from "./ImportData";
import FlexBox from "UtilityComponents/FlexBox";

export default function ManageData() {

  return (
    <FlexBox column padding gap> 
      <ExportData />
      <ImportData />
    </FlexBox>
  );
}
