import addCardToCurrentSlot from "Functions/Slot/addCardToCurrentSlot";
import { setCurrentCardId } from "Functions/Card/setCurrentCardId";
import { cards } from "Signals/cards";
import DisplayCard from "UtilityComponents/DisplayCard";
import React from "react";

export default function ListSelectedCardsItem({ cardId }) {
  const card = cards.value.find((card) => card.id === cardId);

  const handleClick = () => {
    addCardToCurrentSlot(card.id)
  };
  return (
    <DisplayCard card={card} onClick={handleClick} onDoubleClick={undefined} selected />
  );
}
