import { Button, Card, Tooltip } from "@blueprintjs/core";
import { addSnapshot } from "Functions/Snapshot/addSnapshot";
import React from "react";

export default function AddSnapshot() {

  const handleClick = () => {
    addSnapshot();
  };
  return (
    <Tooltip content="Add a snapshot without generating an image. Will contain current hand only.">
    <Button icon="add" intent="primary" onClick={handleClick}>
      Add Snapshot
    </Button>
    </Tooltip>
  );
}
