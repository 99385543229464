import { Button } from "@blueprintjs/core";
import { deleteUnsavedSnapshots } from "Functions/Snapshot/deleteUnsavedSnapshots";
import React from "react";

export default function DeleteSnapshots() {
  return (
    <Button
      icon="delete"
      intent="danger"
      onClick={() => deleteUnsavedSnapshots()}
    >
      Delete unsaved
    </Button>
  );
}
