import { Card } from "@blueprintjs/core";
import React from "react";
import RenderCardImage from "./RenderCardImage";
import { settings } from "Signals/settings";

export default function DisplayCard({
  card,
  onClick,
  onDoubleClick,
  disabled = false,
  selected = false,
  compact = false,
}) {
  if(!card) return null
  const style = {
    width: compact ? `${settings.value.cardWidth / 2}px` : `${settings.value.cardWidth}px`,
    // height: disabled ? "1rem" : compact ? `${cardHeight/ 3}px` : `${cardHeight}px`,
    height: compact
      ? `${settings.value.cardHeight / 2}px`
      : `${settings.value.cardHeight}px`,
    opacity: disabled ? 0.2 : 1,
    padding: 0,
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    position: "relative",
    background: card.color,
  };

  const titleStyle = {
    position: "absolute",
    // bottom: card.image ? 0 : "50%",
    // bottom: "50%",
    bottom: 0,
    width: "100%",
    background: "white",
    margin: 0,
    textAlign: "center",
    // fontSize: compact ? "0.6rem" : "1rem",
  };

  const loraStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    margin: 0,
    fontSize: "0.6rem",
    background: "green",
    color: "white",
    padding: "0.2rem",
  };

  const containsLora = () => {
    // return cardContainLora(card.id);
    return card.lora
  };

  return (
    <Card
      elevation={selected ? 3 : 0}
      // @ts-ignore
      style={style}
      className={`${disabled && "disabled"} ${selected && "selected"}`}
      interactive
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <div style={{height:'100%'}}>
        <p
          // @ts-ignore
          style={titleStyle}
        >
          {card.name}
        </p>
        {containsLora() && (
          <p
            // @ts-ignore
            style={loraStyle}
          >
            LORA
          </p>
        )}
        <RenderCardImage card={card} />
      </div>
    </Card>
  );
}
