import {
  Card,
  CardList,
  Divider,
  Section,
  SectionCard,
} from "@blueprintjs/core";
import React from "react";
import Sampler from "./Sampler";
import CFGScale from "./CFGScale";
import ClipSkip from "./ClipSkip";
import UpdateSteps from "./UpdateSteps";
import UpdateWidth from "./UpdateWidth";
import UpdateHeight from "./UpdateHeight";
import HeadingCard from "UtilityComponents/HeadingCard";
import FlexBox from "UtilityComponents/FlexBox";

export default function Txt2Img() {
  return (
    <HeadingCard title="Settings">
      <FlexBox column bigGap>
        <Sampler />
        <UpdateSteps />
        <CFGScale />
        <ClipSkip />
        <UpdateWidth />
        <UpdateHeight />
      </FlexBox>
    </HeadingCard>
  );
}
