import { Checkbox } from "@blueprintjs/core";
import { setCurrentCardId } from "Functions/Card/setCurrentCardId";
import addCardToCurrentSlot from "Functions/Slot/addCardToCurrentSlot";
import DisplayCard from "UtilityComponents/DisplayCard";
import React from "react";
import { currentSlot } from "Signals/slots";

export default function ListCardsItem({ card, selected }) {
  const slot = currentSlot.value;
  const handleClick = () => {
    setCurrentCardId(card.id);
    // if (!slot.multiSelect) {
    addCardToCurrentSlot(card.id);
    // }
  };

  const handleDoubleClick = () => {
    // newGeneration();
  };

  const handleCheckboxChange = () => {
    addCardToCurrentSlot(card.id);
  };

  const style = {
    Position: "relative",
    // width: "100%",
    // height: "100%",
  };

  const toggleStyle = {
    // backgroundColor: "white",
    position: "absolute",
    top: "5",
    left: "5",
    zIndex: "99",
  };

  return (
    <div
      // @ts-ignore
      style={style}
    >
      {/* {slot.multiSelect && (
        <div
          // @ts-ignore
          style={toggleStyle}
        >
          <Checkbox large checked={selected} onChange={handleCheckboxChange} />
        </div>
      )} */}
      <DisplayCard
        card={card}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        selected={selected}
        // selected={false}
      />
    </div>
  );
}
