import { Card, HTMLSelect, MenuItem } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import { categories } from "Signals/categories";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function ChangeCategory({ slot }) {
  const handleSelect = (categoryId) => {
    updateSlot({
      ...slot,
      categoryId: parseInt(categoryId),
    });
  };

  const style = {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
  };

  return (
    <Card style={style} compact>
      <FlexBox spaceBetween fullWidth>
        <p className="m-0">Category</p>
        <HTMLSelect
          value={slot.categoryId}
          onChange={(e) => handleSelect(e.target.value)}
        >
          {categories.value.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </HTMLSelect>
      </FlexBox>
    </Card>
  );
}
