import React from "react";
import FlexBox from "UtilityComponents/FlexBox";
import UploadImage from "./UploadImage";
import UseSnapshotImage from "./Actions/UseSnapshotImage";
import Paint from "./Actions/Paint";
import { Card } from "@blueprintjs/core";

export default function Image() {
  return (
    <Card>
      <FlexBox column gap>
        <UploadImage />
        <FlexBox gap>
          <UseSnapshotImage />
          <Paint />
        </FlexBox>
      </FlexBox>
    </Card>
  );
}
