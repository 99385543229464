import { EditableText, H1, H2 } from "@blueprintjs/core";
import { updateCard } from "Functions/Card/updateCard";
import HeadingCard from "UtilityComponents/HeadingCard";
import TitleText from "UtilityComponents/TitleText";
// import { updateCard } from "Signals/cards";
import React, { useEffect } from "react";

export default function UpdateName({ card }) {
  const [name, setName] = React.useState(card.name);
  useEffect(() => {
    setName(card.name);
  }, [card.name]);

  const handleUpdate = () => {
    updateCard({ ...card, name });
  };

  return (
    <HeadingCard>
      <TitleText>
        <EditableText
          // multiline
          selectAllOnFocus={true}
          placeholder="Name"
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          onConfirm={handleUpdate}
        />
      </TitleText>
    </HeadingCard>
  );
}
