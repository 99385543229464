import { Checkbox } from "@blueprintjs/core";
import { updateToken } from "Functions/Token/updateToken";
import React from "react";

export default function ToggleTokenEnable({ token }) {
  return (
    <Checkbox
      checked={token.enabled}
      onChange={() => updateToken({ ...token, enabled: !token.enabled })}
    >
    Enabled
    </Checkbox>
  );
}
