import { Checkbox, Switch, Tooltip } from "@blueprintjs/core";
import { CheckBox } from "@mui/icons-material";
import { updateSlot } from "Functions/Slot/updateSlot";
import React from "react";

export default function ToggleMultiSelect({ slot }) {
  return (
    <Checkbox
      style={{ margin: 0 }}
      checked={slot.multiSelect}
      onChange={() => updateSlot({ ...slot, multiSelect: !slot.multiSelect })}
    >
      Multi-select
    </Checkbox>
  );
}
