import { Button, ButtonGroup, Tooltip } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import { currentSlot } from "Signals/slots";
import React from "react";

export default function Sort() {
  const slot = currentSlot.value;
  if(!slot.filter) return null;
  return (
    <ButtonGroup>
      <Tooltip content="Sort by name">
        <Button
          active={slot.filter.sort === "name"}
          onClick={() =>
            updateSlot({ ...slot, filter: { ...slot.filter, sort: "name" } })
          }
          icon="sort-alphabetical"
        />
      </Tooltip>
      <Tooltip content="Sort by created date">
        <Button
          active={slot.filter.sort === "created"}
          onClick={() =>
            updateSlot({ ...slot, filter: { ...slot.filter, sort: "created" } })
          }
          icon="calendar"
        />
      </Tooltip>
      <Tooltip content={slot.filter.sortAsc ? "Ascending" : "Descending"}>
        <Button
          onClick={() =>
            updateSlot({
              ...slot,
              filter: { ...slot.filter, sortAsc: !slot.filter.sortAsc },
            })
          }
          icon={slot.filter.sortAsc ? "sort-asc" : "sort-desc"}
        />
      </Tooltip>
    </ButtonGroup>
  );
}
