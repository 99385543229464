import { SwitchCard } from "@blueprintjs/core";
import { updateSlot } from "Functions/Slot/updateSlot";
import React from "react";

export default function ToggleWildcard({ slot }) {
  return (
    <SwitchCard
      checked={slot.wildcard}
      // onChange={() => updateSlot(slot.id, { wildcard: !slot.wildcard })}
      onChange={() => updateSlot({ ...slot, wildcard: !slot.wildcard })}
    >
      Wildcard
    </SwitchCard>
  );
}
