import { Card, Slider } from "@blueprintjs/core";
import { settings } from "Signals/settings";
import FlexCard from "UtilityComponents/FlexCard";
import React from "react";

export default function UIOptions() {
  return (
    <div className="p-1">
      <FlexCard>
        <p>Card Width</p>
        <Slider
          value={settings.value.cardWidth}
          onChange={(value) =>
            (settings.value = { ...settings.value, cardWidth: value })
          }
          min={10}
          max={1000}
        />
      </FlexCard>
      <FlexCard className="mt-1">
        <p>Card Height</p>
        <Slider
          value={settings.value.cardHeight}
          onChange={(value) =>
            (settings.value = { ...settings.value, cardHeight: value })
          }
          min={10}
          max={1000}
        />
      </FlexCard>
    </div>
  );
}
