import { Checkbox } from "@blueprintjs/core";
import { apiSettings } from "Signals/apiSettings";
import FlexBox from "UtilityComponents/FlexBox";
import React from "react";

export default function InvertMask() {
  return (
    <FlexBox column>
      <p>Invert Mask</p>
      <Checkbox
        checked={apiSettings.value.inpainting_mask_invert === 1 ? true : false}
        onChange={(event) => {
          apiSettings.value = {
            ...apiSettings.value,
            inpainting_mask_invert: event.target.checked ? 1 : 0,
          };
        }}
      />
    </FlexBox>
  );
}
