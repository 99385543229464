import { apiSettings } from "Signals/apiSettings";
import axios from "axios";

export const getCnModels = async () => {
  const url = `${apiSettings.value.apiUrl}/controlnet/model_list`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    if (data.model_list.length === 0) {
      return;
    }
    apiSettings.value = {
      ...apiSettings.value,
      cnModels: data.model_list,
      // cnModels: data.flatMap((model) => model.title),
    };
  } catch (error) {
    // state.apiStatus = "Error fetching data from API";
  }
};
